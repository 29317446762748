import { Component, OnDestroy, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { Observable, combineLatest, of, Subscription } from 'rxjs';
import { map, mergeMap } from "rxjs/operators";
import { ValidatorFn, AbstractControl, FormGroup, FormControl } from "@angular/forms";
import { DwConfigServiceToken, DwConfigService, DwSecurityUserService, DwComponent, DwComponentType, DwMetaDataService,
         DwSectionBaseComponent, DwExpressionService, DwUiConfigRegistryService, DwUIMetaDataConfig,
         DwMetaDataFormApi,
         DwUIMetaDataConfigToken, DwMetaDataServiceToken, DwMetaDataFormStateService, DwFormActionInterceptor } 
        from '@devwareapps/devware-cap';
import { AviatorUser } from '../../../shared/models/aviator-user.model';
import { Inject, Injectable } from "@angular/core";
import { AppMetaDataItemNames, ProcessorConfigEntity, PaymentMethodEntity  } 
        from '../../../../meta-data/app-meta-data.service';
import { PaymentsRepositoryService } from "src/app/features/payments/services/payments-repository.service";
import { PaymentsIntegrationService } from "src/app/features/payments/services/payments-integration.service";
import { HttpClient } from "@angular/common/http";
import { CreateCustomerResult } from "src/app/features/payments/models/create-customer-result.model";
import { DateTimeUtilService } from '../../../shared/util/date-time-util.service';

import { environment } from 'src/environments/environment';
import { EnvironmentConfigItem } from 'src/environments/models/environment-config.model';
import { AppPermissions } from 'src/app/meta-data/app-permissions.enum';

@DwComponent({
  key: 'payments-add-payment',
  name: 'Payments (Add Payment)',
  componentType: DwComponentType.formSection,
  isGlobal: true,
  //parentItemName: [ AppMetaDataItemNames.ProcessorConfig ]
})
@Component({
  selector: 'app-payments-add-payment',
  templateUrl: './payments-add-payment.component.html',
  styleUrls: ['./payments-add-payment.component.scss']
})
export class PaymentsAddPaymentComponent extends DwSectionBaseComponent 
  implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];
  aviatorUser: AviatorUser;
  paymentsApi: string;

  constructor(
    dwExpressionService: DwExpressionService,
    protected dwUiConfigRegistryService: DwUiConfigRegistryService,
    @Inject(DwUIMetaDataConfigToken) uiMetaDataConfig: DwUIMetaDataConfig,
    @Inject(DwMetaDataServiceToken) dwMetaDataService: DwMetaDataService,
    private dwFormStateService: DwMetaDataFormStateService,
    private dwSecurityUserService: DwSecurityUserService,
    private paymentsRepoSvc: PaymentsRepositoryService,
    private paymentsIntSvc: PaymentsIntegrationService,
    private dateTimeUtilService: DateTimeUtilService,
    private http: HttpClient,
    @Inject(DwConfigServiceToken) private configService: DwConfigService) 
  {
    super(dwExpressionService, dwUiConfigRegistryService, uiMetaDataConfig, dwMetaDataService);
    this.paymentsApi = `${configService.coreConfig.apiRoot}/aviator-online/Payments`;
    this.formApi = this.dwFormStateService.state.formApi;
  }

  formApi: DwMetaDataFormApi;
  stripe : any;
  model: any = { customerId: undefined, billing: { name: undefined, email: undefined } }
  isFSAdmin: boolean = false;
  test : boolean = false;
  nameOnCard:string = undefined;
  billingEmail:string = undefined;
  paymentReady : boolean = false;
  payment: any;
  elements: any;
  alreadyDefault: boolean;
  makeDefault: boolean;
  processorConfig: ProcessorConfigEntity = undefined;
  
  setupComponentComplete(){
    this.formGroup = new FormGroup({
      nameOnCard: new FormControl(),
      billingEmail: new FormControl(),
      makeDefault: new FormControl(),
    });
  }
  ngOnInit() {
    debugger;
    this.test = this.isTest();
    this.stripe = (globalThis as any).stripe;

    const user = this.dwSecurityUserService.securityContext.ApplicationUser;
    this.aviatorUser = new AviatorUser(user, null);
    this.isFSAdmin = this.aviatorUser?.user.Permissions.indexOf(AppPermissions.flightSchoolAdmin) > -1;
    if (!this.isFSAdmin)
      return;
    this.paymentsIntSvc.retrieveProcessorConfig()
        .subscribe( cfg => {
          this.processorConfig = cfg;
          this.model.customerId = cfg.CustomerId;
          this.setupPaymentElement();
        })
  }
  onChange(cardName: string) { 
    debugger;
    //this.isReady(this.card?.complete, cardName);
  }

  // isReady(complete?:boolean, name?: string, err?: string){
  //   const badNameErr = 'The name on the card must be provided.';
  //   const badName = (name ?? '').length == 0;
  //   if (badName && err == undefined) err = badNameErr;

  //   this.nameOnCard = name;
  //   this.currentError = err ?? this.cardError;
  //   this.cardNotReady = complete != true || badName;
  //   if (this.cardNotReady == false)
  //     this.currentError = undefined;
  // }

  createPaymentIntent() : Observable<ICreatePaymentIntent> {
    const url = `${this.paymentsApi}/setup-future-payment`;
    return this.http.get<ICreatePaymentIntent>(url);
  }

  setupPaymentElement(){
    this.createPaymentIntent()
        .subscribe(r => {
          debugger;
          const clientSecret = r.ClientSecret;
          const appearance = { theme: 'stripe' };
          const options = { 
            layout: 'accordion', appearance, clientSecret : clientSecret,
            fields: { link: { enabled: false } }
          };
          this.elements = this.stripe.core.elements({ clientSecret });
          this.payment = this.elements.create('payment', options);
          this.payment.mount('#payment-element');
          this.payment.on('change', (event) => {
            this.paymentReady = event.complete;
          });
        });
  }

  // resetCard(){
  //   this.card.clear();
  //   this.cardError = null;
  //   document.getElementById('card-errors').textContent = '';
  // }
  // hasCardError(cardError?: string, complete?: boolean){
  //   this.cardError = cardError;
  //   this.isReady(complete, this.nameOnCard, cardError);
  // }

  onSubmit(){
    const asyncFunc = async () => {
      debugger;
      //const returnUrl = 'http://localhost:4201/admin/lab/payments/processor-config';
      const { setupIntent, error } = await this.stripe.core.confirmSetup({
        elements : this.elements, 
        redirect: 'if_required', 
        confirmParams: { /* return_url: returnUrl */ },
      });

      if (error) {
        debugger;
        //console.error(error.message);  // Display error message to the user
        return;
      } 

      debugger;
      //this.resetCard();
      let pm: PaymentMethodEntity = {
        _itemName: AppMetaDataItemNames.PaymentMethod,
        ProcessorConfigId: this.processorConfig.ProcessorConfigId,
        PaymentId: setupIntent.payment_method,
        ClientSecret: setupIntent.client_secret,
        SetupIntentId: setupIntent.id,
        IsDefault:this.makeDefault,
        BillingEmail: this.billingEmail,
        BillingName: this.nameOnCard
      };
      this.paymentsRepoSvc.savePayment(pm)
          .subscribe(pm => {
            const url = `${this.paymentsApi}/update-payment-method/${pm.PaymentId}`;
            this.http.get(url)
                .subscribe(resp =>{
                  debugger;
                  //this.formApi.patchFormData({});
                });
            });
    };
    asyncFunc();
  }

  ngOnDestroy(): void {
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  isTest() : boolean {
    const environments : EnvironmentConfigItem[] = (environment as any).environments;
    if (environments) {
      const environmentKey = (environment as any).environmentKey;
      return environmentKey == 'Test' || environmentKey == 'TestLocal';
    }
    return false;
  }

}
export interface ICreatePaymentIntent {
  ClientSecret: string,
  ProcessorConfigId: number
}
