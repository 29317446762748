import { Injectable } from "@angular/core";
import { AdminUserProperties } from "../models/admin-user-properties.model";
import { DwEventService, DwSecurityState, DwSecurityStateService, DwSecurityUserService, DwToggleRouterOutletParams, DwUser, TOGGLE_ROUTER_OUTLET_EVENT } from "@devwareapps/devware-cap";
import { AviatorUser } from "../../shared/models/aviator-user.model";
import { AppPermissions } from "../../../meta-data/app-permissions.enum";
import { Observable, of } from "rxjs";
import { SchoolRepositoryService } from "../../schools/services/school-repository.service";
import { tap } from "rxjs/operators";
import { FlightSchoolEntity } from "../../../meta-data/app-meta-data.service";
import { AviatorOnlineUserTypeIds } from "../../shared/models/aviator-user-type.enum";

@Injectable({ providedIn: 'root' })
export class AdminManagerService {

    static isInitialized = false;

    static userProperties: AdminUserProperties;
    static lastSecurityState: DwSecurityState;;

    
    constructor(private dwSecurityUserService: DwSecurityUserService,
        private dwSecurityStateService: DwSecurityStateService,
        private schoolRepositoryService: SchoolRepositoryService,
        private dwEventService: DwEventService
    ) {
        this.setupEvents();
    }

    setupEvents() {
        this.dwSecurityStateService.securityContextChanged()
            .subscribe((securityContext) => {
                if (this.requiresFlightSchoolUser(this.dwSecurityStateService.securityState)) {
                    AdminManagerService.lastSecurityState = this.dwSecurityStateService.securityState;

                    console.log("Flight School Security Change", securityContext);

                    if (this.dwSecurityStateService.securityState.isAuthenticated) {
                        const appUser = this.dwSecurityUserService.securityState.securityContext.ApplicationUser;

                        if (appUser) {
                            const aviatorUser = new AviatorUser(appUser, null);

                            this.setCurrentFlightSchool(aviatorUser.FlightSchoolId).subscribe();
                        }
                    }
                } 
            });
    }

    requiresFlightSchoolUser(securityState: DwSecurityState): boolean {
        if (!AdminManagerService.lastSecurityState) {
            return false;
        }

        if (AdminManagerService.lastSecurityState.isAuthenticated != securityState.isAuthenticated) {
            return true;
        }

        if (AdminManagerService.lastSecurityState.securityContext.ApplicationUser?.UserId != securityState.securityContext.ApplicationUser?.UserId) {
            return true;
        }
    }

    initAdminProperties() {

    }


    getAviatorUser(user?: DwUser): AviatorUser {
        if (!user) {
            user = this.dwSecurityUserService.securityState?.securityContext?.ApplicationUser;
        }
        return new AviatorUser(user, this.getAdminUserProperties(new AviatorUser(user, null)));
    }

    getAdminUserProperties(user: AviatorUser): AdminUserProperties {

        let adminUserProperties: AdminUserProperties = AdminManagerService.userProperties;

        if (!AdminManagerService.isInitialized && user?.user?.UserName) {
            adminUserProperties = this.getLocalStorageProperties(user.user.UserName);

            AdminManagerService.userProperties = adminUserProperties;
            AdminManagerService.isInitialized = true;
        }

        if (!adminUserProperties) {
            adminUserProperties = {
                userId: user?.user?.UserId,
                userTypeMode: this.getCurrentUserTypeByPermissions(), // user?.UserTypeId,
                flightSchoolId: user?.FlightSchoolId,
                flightSchoolName: user?.FlightSchoolName,
                flightSchoolBrandingThemeId: user?.FlightSchoolBrandingThemeId
            }
        }

        adminUserProperties.userTypeMode = this.getCurrentUserTypeByPermissions();

        return adminUserProperties;
    }

    getCurrentUserTypeByPermissions(): number {
        let userTypeId = AviatorOnlineUserTypeIds.student;

        if (this.dwSecurityUserService.hasPermission(AppPermissions.instructorAccess)) {
            userTypeId = AviatorOnlineUserTypeIds.flightInstructor;
        }

        if (this.dwSecurityUserService.hasPermission(AppPermissions.flightSchoolAccess)) {
            userTypeId = AviatorOnlineUserTypeIds.flightSchool;
        }

        return userTypeId;
    }


    public setCurrentFlightSchool(flightSchoolId): Observable<any> {

        var adminProperties = this.getLocalStorageProperties(this.dwSecurityUserService.securityState.securityContext.ApplicationUser?.UserName);

        if (adminProperties?.flightSchoolId == flightSchoolId && adminProperties?.flightSchoolName) {
            return of();
        }

        return this.schoolRepositoryService.getFlightSchool(flightSchoolId)
            .pipe(tap((flightSchool: FlightSchoolEntity) => {

                this.toggleMainRouterVisibility('Changing Flight School');

                this.updateAdminProperties({
                    flightSchoolId: flightSchool.FlightSchoolId,
                    flightSchoolName: flightSchool.FlightSchoolName,
                    flightSchoolBrandingThemeId: flightSchool.FlightSchoolBranding?.ThemeId
                });

            }));
    }


    private toggleMainRouterVisibility(message: string) {

        const toggleDataEvent: DwToggleRouterOutletParams = {
            toggleTimeMs: 1000,
            message: message
        };

        this.dwEventService.publishEvent(TOGGLE_ROUTER_OUTLET_EVENT, toggleDataEvent);
    }

    public updateAdminProperties(properties: Partial<AdminUserProperties>) {
        if (!this.dwSecurityUserService.hasPermission(AppPermissions.lMSSetupAdmin)) {
            return;
        }

        const existingProperties = this.getAdminUserProperties(new AviatorUser(this.dwSecurityUserService.securityState.securityContext.ApplicationUser, null));

        const updatedProperties = {
            ...existingProperties,
            ...properties
        }

        this.setLocalStorageProperties(this.dwSecurityUserService.securityState.securityContext.ApplicationUser?.UserName, updatedProperties);

        AdminManagerService.userProperties = updatedProperties;

        this.dwSecurityStateService.setSecurityContext({ ...this.dwSecurityUserService.securityState.securityContext, lastUpdated: new Date() } as any);
    }

    private getLocalStorageProperties(userName: string): AdminUserProperties {
        const key = `AdminUserProperties-${userName}`;

        let properties = localStorage.getItem(key);

        if (properties) {
            try {
                return JSON.parse(properties);
            } catch (e) {
                console.error(e);
                return null;
            }
        }

        return null;
    }

    private setLocalStorageProperties(userName: string, properties: AdminUserProperties) {
        const key = `AdminUserProperties-${userName}`;

        localStorage.setItem(key, JSON.stringify(properties));
    }


}