import { Injectable } from "@angular/core";
import { DwEventService, DwOrmDataService } from "@devwareapps/devware-cap";

@Injectable({
  providedIn: 'root'
})
export class EventManagementService {
    constructor(private dwEventService : DwEventService) {

    }

    public addEventReplicator(eventReplicationItem: EventReplicationItem) {
        const topicName =  DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(eventReplicationItem.itemName);

        this.dwEventService.getEvent(topicName).subscribe(() => {
            for(var replicateItemName of eventReplicationItem.replicateItemNames) {
                this.dwEventService.publishEvent(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(replicateItemName), {});
            }
        });
    }
}


export interface EventReplicationItem {
    itemName: string;
    replicateItemNames: string[];
}