import { Injectable } from "@angular/core";
import { DwContextDefinition, DwAttributeType, DwControlType, DwQueryMdAttribute, DwMdAttribute, DwFieldType, DwUserContextDynamicHandler, DwUserExtendedData } from "@devwareapps/devware-cap";
import { Observable } from "rxjs";
import { AppMetaDataLookups, UserTypeAllItems } from "../../../meta-data/app-meta-data.service";
import { AdminManagerService } from "../services/admin-manager.service";


@Injectable()
export class AviatorUserContextCustomizerService implements DwUserContextDynamicHandler {

    constructor(private adminManagerService: AdminManagerService) {

    }

    setupUserContextDefinition(userContextDef: DwContextDefinition, userExtendedData: DwUserExtendedData): void | Observable<any> {
      //  console.log('UserContextDynamicHandler - setupUserContextDefinition');

        this.addProperty(userContextDef.attributes, 'UserTypeMode', 'User Type Mode', DwAttributeType.Integer, DwControlType.DropDownList, AppMetaDataLookups.UserTypeAllItems);
    }
    customizeUserContext(userContext: any, context: any, contextData: any) {
        const aviatorUser = this.adminManagerService.getAviatorUser(userContext);

        //console.log('UserContextDynamicHandler - getCustomContextFunction', userContext);
        userContext.UserTypeMode = aviatorUser.UserTypeId;
        userContext.FlightSchoolId = aviatorUser.FlightSchoolId;
        userContext.FlightSchoolName = aviatorUser.FlightSchoolName;
        userContext.FlightSchoolBrandingThemeId = aviatorUser.FlightSchoolBrandingThemeId;

        //console.log('UserContextDynamicHandler - customizeUserContext', userContext);
        return userContext;
    }

    protected addProperty(attributes: DwQueryMdAttribute[], name: string, display: string, attrType?: DwAttributeType, controlType? : DwControlType, lookupId?: number): DwQueryMdAttribute {
        let attr : DwMdAttribute = {
            AttributeName : name,
            Display : display,
            AttributeType : attrType || DwAttributeType.Text,
            MetaDataControlTypeId : controlType || DwControlType.Textbox,
            MetaDataLookupId : lookupId,
            IsLookup: !!lookupId,
        };

        let mdQueryAttr: DwQueryMdAttribute = {
            Attribute: attr,
            FieldType: DwFieldType.Standard,
            AttributeDisplay : attr.Display,
            AttributeType: attr.AttributeType,
            AttributeName : attr.AttributeName,
            HasDisplayField: false,
            DisplayAttributeName: attr.AttributeName,
        }

        attributes.push(mdQueryAttr);

        return mdQueryAttr;
    }
}