<!-- <div dwRow [hidden]="!test">
    <div dwCol="3">Payment succeeds: 4242 4242 4242 4242</div>
    <div dwCol="3">Payment requires authentication: 4000 0025 0000 3155</div>
    <div dwCol="3">Payment is declined: 4000 0000 0000 9995</div>
</div> -->
<div dwRow [hidden]="isFSAdmin == true">
    <div dwCol="6" class="pending-load-id">You must be admin for your Flight School</div>
</div>
<div dwRow [hidden]="model?.customerId != undefined">
    <div dwCol="6" class="pending-load-id">Loading Payment Processor Customer ID...</div>
</div>
<div dwRow>
    <div dwCol="9">
        <div dwRow>
            <div dwCol="2"><span class="payment-label">Customer Id:&nbsp;</span><br/>{{model?.customerId}}</div>
            <div dwCol="2"><span class="payment-label">Billing Name:&nbsp;</span><br/>{{model?.billing.name}}</div>
            <div dwCol="2"><span class="payment-label">Billing Email:&nbsp;</span><br/>{{model?.billing?.email}}</div>
        </div>
    </div>
</div><br/>
<form [formGroup]="formGroup" #addCard="ngForm" (ngSubmit)="onSubmit()">
    <div dwRow>
        <div dwCol="8" class="payment-label">New Credit Card Details</div>  
    </div>  
    <div dwRow>
        <div dwCol="1" class="name-label">
            Name on Card&nbsp;<span class="pending-load-id">*</span>
        </div>
        <div dwCol="3" class="">
            <input type="text" id="nameOnCard" (ngModelChange)="onNameChange($event)" class="form-control" 
            [(ngModel)]="nameOnCard" formControlName="nameOnCard" required>
        </div>
        <div dwCol="2" class="">&nbsp;
            <input type="checkbox" class="check-input card-default" [disabled]="alreadyDefault == true" 
                   [(ngModel)]="makeDefault" id="makeDefault" formControlName="makeDefault" />
            Is Default
        </div>
        <div dwCol="2" class="">
            <button (click)="resetCard()" type="button" class="btn-sm card-reset">Reset</button>
        </div>
        <div dwCol="4"><!-- Right-side --></div>
    </div>
    <div dwRow>
        <div dwCol="1"></div>
        <div dwCol="5" class="vertical-label card-element">
            <div id="card-element"></div>
            <div class="pending-load-id card-error">{{currentError}}</div>  
        </div>
        <div dwCol="2">
            <button [hidden]="cardNotReady == true" type="submit" class="btn-sm create-card-submit">Add New Card</button>
        </div>
        <div dwCol="4"><!-- Right-side --></div>
    </div>
</form>
