import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CourseRepositoryService } from '../../../courses/services/course-repository.service';
import { CourseEntity } from '../../../../meta-data/app-meta-data.service';
import { TreeNode } from 'primeng/api';
@Component({
  selector: 'app-lesson-selection-tree-control',
  templateUrl: './lesson-selection-tree-control.component.html',
  styleUrls: ['./lesson-selection-tree-control.component.scss']
})
export class LessonSelectionTreeControlComponent implements OnInit {

  
  @Input() courseId: number;

  @Input() selectedLessonIds: number[];
  @Output() selectedLessonIdsChange = new EventEmitter<number[]>();

  course: CourseEntity; 

  treeData: TreeNode[] = [];

  selectedNodes : TreeNode[] = [];


  constructor(private courseRepositoryService: CourseRepositoryService) { }
  
  ngOnInit(): void {
    this.loadCourse();
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.courseId) {
      this.loadCourse();
    }
    if (changes.selectedLessonIds) {
      this.updateSelectedNodes();
    }
  }

  updateSelectedNodes(expandNodes: boolean = false) {
    const selectedNodes: TreeNode[] = [];

    for(var moduleNode of this.treeData) {
      let allSelected = true;
      let anySelected = false;
      for(var lessonNode of moduleNode.children) {
        if (this.selectedLessonIds?.includes(lessonNode.data)) {
          selectedNodes.push(lessonNode);
          anySelected = true;
        } else {
          allSelected = false;
        }
      }
      if (allSelected) {
        selectedNodes.push(moduleNode);
      }

      if (anySelected && expandNodes) {
        moduleNode.expanded = true;
      }
    }

    this.selectedNodes = selectedNodes;
  }

  nodeSelect(event: any) {
    const lessonIds: number[] = [];

    for(var node of this.selectedNodes) {
      if (node.type == 'lesson') {
        lessonIds.push(node.data);
      }
    }

    this.selectedLessonIds = lessonIds;
    this.selectedLessonIdsChange.emit(this.selectedLessonIds);
  }

  private loadCourse() {

    if (this.courseId && this.courseId != this.course?.CourseId)

    this.courseRepositoryService.getCourseHierarchy(this.courseId).subscribe(course => {
        this.course = course;

        this.buildTree();
    });
  }

  buildTree() {
    this.treeData = [];
    this.selectedNodes = [];
    if (!this.course) {
      return;
    }

    for(let module of this.course.CourseModule) {
      const moduleNode: TreeNode = {
        label: module.ModuleName,
        data: module.CourseModuleId,
        type: 'module',
        expanded: false,
        children: []
      };

      this.treeData.push(moduleNode);

      for(let lesson of module.CourseLesson) {
        const lessonNode: TreeNode = {
          label: lesson.LessonName,
          data: lesson.CourseLessonId,
          type: 'lesson',
          expanded: false,
          children: []
        };

        // if (this.selectedLessonIds?.includes(lesson.CourseLessonId)) {
        //   this.selectedNodes.push(lessonNode);
        // }

        moduleNode.children.push(lessonNode);
      }

    }

    this.updateSelectedNodes(true);
  }
}
