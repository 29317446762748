import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DwConfigServiceToken, DwConfigService, DwSecurityUserService, DwAuthenticationType, DwComponent, DwComponentType } from '@devwareapps/devware-cap';
import { AviatorUser } from '../../../shared/models/aviator-user.model';
import { Inject, Injectable } from "@angular/core";
import { AppMetaDataItemNames, ProcessorConfigEntity, PaymentMethodEntity  } 
        from '../../../../meta-data/app-meta-data.service';
import { HttpClient } from "@angular/common/http";

@DwComponent({
  key: 'lab-payments',
  name: 'Payments (Lab)',
  componentType: DwComponentType.formSection,
  isGlobal: false,
  parentItemName: [ AppMetaDataItemNames.ProcessorConfig ]
})
@Component({
  selector: 'app-lab-payments',
  templateUrl: './lab-payments.component.html',
  styleUrls: ['./lab-payments.component.scss']
})
export class LabPaymentsComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  aviatorUser: AviatorUser;

  paymentsApi: string;
  constructor(
        private dwSecurityUserService: DwSecurityUserService,
        private http: HttpClient,
        @Inject(DwConfigServiceToken) private configService: DwConfigService) 
  {
    this.paymentsApi = `${configService.coreConfig.apiRoot}/aviator-online/Payments/`;
  }

  stripe : any;
  ngOnInit() {
    this.stripe = (globalThis as any).stripe;


    const url1 = `${this.paymentsApi}/checkout`;
    const fetchClientSecret = async () => {
      const response = await fetch(url1, { method: "POST", });
      const { clientSecret } = await response.json();
      const checkout: any = await this.stripe.initEmbeddedCheckout({ clientSecret, });
      checkout.mount('#checkout');
    };
    //fetchClientSecret();
  }
  createInvoices(){ this.http.get(`${this.paymentsApi}generate-invoices`).subscribe(); }
  syncProducts(){ this.http.get(`${this.paymentsApi}sync-products`).subscribe(); }
  ngOnDestroy(): void {
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

}