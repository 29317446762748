import { Component, Inject, OnInit } from '@angular/core';
import { deepCopy, DwComponent, DwComponentType, DwExpressionService, DwImageResizeOptions, DwImageSizeOptions, DwMediaEntity, DwMediaRepositoryService, DwMetaDataFormApi, DwMetaDataFormSectionStateService, DwMetaDataFormStateService, DwMetaDataService, DwMetaDataServiceToken, DwOrmDataService, DwOrmDataServiceToken, DwRoMediaEntity, DwSectionBaseComponent, DwSectionReadonlyBaseComponent, DwThemeManagerService, DwThemeRespositoryService, DwUiConfigRegistryService, DwUIMetaDataConfig, DwUIMetaDataConfigToken } from '@devwareapps/devware-cap';
import { AppMetaData, AppMetaDataItemNames, AppMetaDataLookups, CourseLessonEntity, DwThemeEntity, DwThemePropertyDefinitionEntity, FlightSchoolEntity, LessonTypeAllItems, StandardColorSetEntity, VideoEntity } from '../../../../meta-data/app-meta-data.service';
import { LessonNavigationInfo } from '../../../courses/models/lesson-navigation-info.model';
import { LessonProgressHierarchy } from '../../../courses/models/lesson-progress-hierarchy.model';
import { CourseNavigationService } from '../../../courses/services/course-navigation.service';
import { LessonFlagUtilService } from '../../../courses/services/lesson-flag-util.service';
import { DateTimeUtilService } from '../../../shared/util/date-time-util.service';
import { DwThemeProperties } from '../../models/theme-properties.enum';
import { SchoolRepositoryService } from '../../services/school-repository.service';
import { FormControl, ValidatorFn } from '@angular/forms';
import { CourseRepositoryService } from '../../../courses/services/course-repository.service';


@DwComponent({
   key: 'SchoolBrandingSetup',
   name: 'School Branding Setup',
   componentType: DwComponentType.formSection,
   parentItemName: AppMetaDataItemNames.FlightSchoolBranding,

})
@Component({
  selector: 'app-school-branding-setup',
  templateUrl: './school-branding-setup.component.html',
  styleUrls: ['./school-branding-setup.component.scss']
})
export class SchoolBrandingSetupComponent extends DwSectionReadonlyBaseComponent {
  flightSchool: FlightSchoolEntity;
  theme: DwThemeEntity;
  formApi: DwMetaDataFormApi; 
  autoApplyTheme = true;
  colorSetLookupId = AppMetaDataLookups.StandardColorSetAllItems;

  showAdjustLogo = false;
 
  readonly DwThemeProperties = DwThemeProperties;
  
  logoMediaImageReSize: DwImageResizeOptions = {
    cropWidth: 200,
    cropHeight: 200,
    cropRound: false,
    outputWidth: 400,
    outputHeight: 400,
    allowResize: true
  };

  logoSizeOptions: DwImageSizeOptions = {
     cropToCircle: true,
     height: 150,
     width: 150,
  }
  colorSets: StandardColorSetEntity[];
  isReadOnly: boolean;
  themeControl: FormControl;
  navigationColorSetId: FormControl;
  sidebarColorSetId: FormControl; 


  setSideLogoMedia(media: DwRoMediaEntity) {
    this.isSectionReadOnly
    this.sideLogoMedia = media;

    this.theme.AlternateLogoMediaId = media?.MediaId;
    this.theme.LogoLoginMediaId = media?.MediaId;


    this.showAdjustLogo = false;
    if (media.ImageWidth && media.ImageHeight) {
      // Determine default size
      if (media.ImageWidth > media.ImageHeight) {
        const ratio = media.ImageHeight / media.ImageWidth;

        this.theme.AlternateLogoWidth = media.ImageWidth > 200 ? 200 : media.ImageWidth;
        this.theme.AlternateLogoHeight = this.theme.AlternateLogoWidth * ratio;
      } else {
        const ratio = media.ImageWidth / media.ImageHeight;

        this.theme.AlternateLogoHeight = media.ImageHeight > 200 ? 200 : media.ImageWidth;
        this.theme.AlternateLogoWidth = this.theme.AlternateLogoHeight * ratio;
      }
    } 
    else {
      if (!this.theme.AlternateLogoWidth) {
        this.theme.AlternateLogoWidth = 125;
      }
  
      if (!this.theme.AlternateLogoHeight) {
        this.theme.AlternateLogoHeight = 125;
      }
    }
  
    this.applyTheme();
  }


  stylePropertyConfig = [{
      propertyDef: DwThemeProperties.topNavigationBackgroundColor,
    },
    {
      propertyDef: DwThemeProperties.topNavigationTextColor,
    },
    // {
    //   propertyDef: DwThemeProperties.topNavigationBarHeight,
    // },
    {
      propertyDef: DwThemeProperties.sidebarBackgroundColor,
    },
    {
      propertyDef: DwThemeProperties.sidebarTextColor,
    },
]
  themeProperyDefs: DwThemePropertyDefinitionEntity[];
  sideLogoMedia: any;
  
  constructor(
    dwExpressionService: DwExpressionService,
    protected dwUiConfigRegistryService: DwUiConfigRegistryService,
    @Inject(DwUIMetaDataConfigToken) uiMetaDataConfig: DwUIMetaDataConfig,
    @Inject(DwMetaDataServiceToken) dwMetaDataService: DwMetaDataService,
    @Inject(DwOrmDataServiceToken) private dataService: DwOrmDataService,
    dwFormStateService: DwMetaDataFormStateService,
    private courseRepositoryService: CourseRepositoryService,
    private courseNavigationService: CourseNavigationService,
    private dateTimeUtilService: DateTimeUtilService,
    private lessonFlagUtilService: LessonFlagUtilService,
    private dwThemeRepositoryService: DwThemeRespositoryService,
    private dwThemeManagerService: DwThemeManagerService,
    private schoolRepositoryService: SchoolRepositoryService,
    dwFormSectionStateService: DwMetaDataFormSectionStateService,
    private dwMediaRepositoryService: DwMediaRepositoryService,
    
  ) {
    super(dwExpressionService, dwFormSectionStateService, dwFormStateService, dwUiConfigRegistryService, uiMetaDataConfig, dwMetaDataService);
    this.formApi = this.dwFormStateService.state.formApi;

    this.dwThemeRepositoryService.getThemePropertyDefinitions().subscribe((themeProperyDefs) => {
      this.themeProperyDefs = themeProperyDefs;
    });
  } 
  
  ngOnInit(): void {
    this.setupFormControls();

    this.sectionReadOnlyChanged(this.isSectionReadOnly());

    this.runAfterFormDataLoaded(() => {
      this.setupForm();
    });

    this.schoolRepositoryService.getColorSets()
      .subscribe(colorSets => {
        this.colorSets = colorSets;

        this.updateColorSet();
      });
  }

  
  setupFormControls() {
    return;
    // Form group represents FlightSchoolBranding table

    const originalValue = this.formApi.getFormData<FlightSchoolEntity>();

    //this.themeControl = this.addControl('DwTheme');
    this.navigationColorSetId = this.addControl('NavigationColorSetId');
    this.sidebarColorSetId = this.addControl('SidebarColorSetId');

   // this.formGroup.patchValue(this.flightSchool.FlightSchoolBranding);
  }

  
  sectionReadOnlyChanged(readOnly: boolean): void {
    this.isReadOnly = readOnly;

    return;
     if (this.isReadOnly) {
      this.navigationColorSetId.disable();
      this.sidebarColorSetId.disable();
    } else {
      this.navigationColorSetId.enable();
      this.sidebarColorSetId.enable();
    }
  }

  updateColorSet() {
    if (!this.flightSchool?.FlightSchoolBranding) {
      return;
    }

    this.setThemeColorSet(this.flightSchool.FlightSchoolBranding.NavigationColorSetId, DwThemeProperties.topNavigationBackgroundColor, DwThemeProperties.topNavigationTextColor);
    this.setThemeColorSet(this.flightSchool.FlightSchoolBranding.SidebarColorSetId, DwThemeProperties.sidebarBackgroundColor, DwThemeProperties.sidebarTextColor);
    this.setThemeColorSet(this.flightSchool.FlightSchoolBranding.LoginColorSetId, DwThemeProperties.loginButtonBackground, DwThemeProperties.loginButtonTextColor);
  }

  autoApplyThemeChange() {
    if (this.autoApplyTheme) {
      this.applyTheme();
    }

  }

  themeChange() {
    this.autoApplyThemeChange();
  }

  applyTheme() {

    const theme = deepCopy(this.theme);

    theme.DwThemeProperty = theme.DwThemeProperty.filter(p => p.PropertyValue);

    this.setupLogo();

    this.dwThemeManagerService.applyTheme(theme).subscribe(() => {});

    this.themeProperyDefs = [...this.themeProperyDefs]
  }

  
  undoTheme() {
    this.dwThemeManagerService.applyDefaultTheme().subscribe(() => {});
  }

  private setupForm() {
    this.flightSchool = this.formApi.getFormData();

    this.theme = this.flightSchool?.FlightSchoolBranding?.DwTheme;


    this.dwThemeManagerService.getMediaCached([this.theme?.AlternateLogoMediaId])
      .subscribe((media) => {
        this.sideLogoMedia = media[0];
        this.setupLogo();
      });
  }

  setThemeColorSet(colorSetId: number, backgroundColorPropertyId: number, foregroundPropertyId: number) {
    const colorSet = this.colorSets.find(c => c.StandardColorSetId === colorSetId);

    this.setThemeProperty(backgroundColorPropertyId, colorSet?.BackgroundColor);
    this.setThemeProperty(foregroundPropertyId, colorSet?.ForegroundColor);
  }

  sideLogoStyle: any = {};

  setImageSizeHeight() {
    this.applyTheme();
  }

  setImageSize() {
    // Make these the same
   // this.theme.AlternateLogoHeight = this.theme.AlternateLogoWidth;

    this.applyTheme();
  }

  setupLogo() {

    const imageUrl = this.dwMediaRepositoryService.getImageUrlFromMediaPath(this.sideLogoMedia?.MediaPath);

    this.sideLogoStyle = {
      'width': `${this.theme.AlternateLogoWidth}px`,
      'height': `${this.theme.AlternateLogoHeight}px`,
      'background-image': `url(${imageUrl})`,
      'background-repeat': 'no-repeat',
      'background-size': 'contain',
      'border': '1px dashed black',
    }
  }


  setThemeProperty(propertyId: number, value: string) {
    let property = this.theme.DwThemeProperty.find(p => p.ThemePropertyDefinitionId === propertyId);

    if (!property) {
      property = AppMetaData.DwThemeProperty.CreateEntity();
      property.ThemePropertyDefinitionId = propertyId;

      this.theme.DwThemeProperty.push(property);
    }

    property.PropertyValue = value;

    if (!value) {
      property._isDeleted = true;
    }

    this.autoApplyThemeChange();
  }


  
  protected addControl(controlName: string, validatorVn: ValidatorFn = null, initialValue?: any): FormControl {
    let control = this.formGroup.get(controlName) as FormControl;

    if (!control) {
      control = new FormControl();

      this.formGroup.addControl(controlName, control);
    }

    if (initialValue) {
        control.setValue(initialValue);
    }

    control.validator = validatorVn;

    control.enable();

    return control;
  }

}
