import { Component, Inject, OnInit } from '@angular/core';
import { DwComponent, DwComponentType, DwEventService, DwExpressionService, DwGridEvents, DwMetaDataFormApi, DwMetaDataFormStateService, DwMetaDataService, DwMetaDataServiceToken, DwSectionBaseComponent, DwUIMetaDataConfig, DwUIMetaDataConfigToken, DwUiConfigRegistryService } from '@devwareapps/devware-cap';
import { AppMetaData, AppMetaDataItemNames, CourseModuleProgressEntity, StudentCourseEntity, StudentCourseProgressByStudentCourseQueryEntity, StudentCourseProgressQueryEntity, StudentEntity, StudentLessonProgressQueryEntity, StudentModuleProgressQueryEntity } from '../../../../meta-data/app-meta-data.service';
import { CourseRepositoryService } from '../../services/course-repository.service';
import { CourseProgressVariableContext } from '../../models/course-progress-variable-context.model';

@DwComponent({
  key: 'courseStudentProgressFormSection',
  name: 'Course Student Progress - Form Section',
  componentType: DwComponentType.formSection,
  parentItemName: AppMetaDataItemNames.StudentCourse,
  isGlobal: false,
})
@Component({
  selector: 'app-course-student-progress',
  templateUrl: './course-student-progress.component.html',
  styleUrls: ['./course-student-progress.component.scss']
})
export class CourseStudentProgressComponent extends DwSectionBaseComponent {
  formApi: DwMetaDataFormApi;
  studentCourseProgress: StudentCourseProgressByStudentCourseQueryEntity;
  studentCourse: StudentCourseEntity;

  moduleListConfig = {
    itemName: AppMetaDataItemNames.CourseModuleProgress,
    configKeyPart: 'student-module-progress',
    filters: {}
  }

  lessonListConfig = {
    itemName: AppMetaDataItemNames.CourseLessonProgress,
    configKeyPart: 'student-lesson-progress',
    filters: {}
  }
  courseModuleProgress: CourseModuleProgressEntity[];
  currentModule: CourseModuleProgressEntity;
  initComplete: boolean;

  constructor(
    dwExpressionService: DwExpressionService,
    protected dwUiConfigRegistryService: DwUiConfigRegistryService,
    @Inject(DwUIMetaDataConfigToken) uiMetaDataConfig: DwUIMetaDataConfig,
    @Inject(DwMetaDataServiceToken) dwMetaDataService: DwMetaDataService,
    private dwFormStateService: DwMetaDataFormStateService,
    private courseRepositoryService: CourseRepositoryService,
    private dwEventService : DwEventService
  ) {
    super(dwExpressionService, dwUiConfigRegistryService, uiMetaDataConfig, dwMetaDataService);
    this.formApi = this.dwFormStateService.state.formApi;
  }
  ngOnInit(): void {
    this.setupSection();

    this.formApi.getVariableContext<CourseProgressVariableContext>().subscribe(context => {
      // if (this.currentModule?.CourseModuleProgressId !== context.Selected_Module_Id) {
      //   if (this.courseModuleProgress) {
      //     this.setCurrentModule(this.courseModuleProgress.find(m => m.CourseModuleProgressId === context.Selected_Module_Id));
      //   }
      // } 

      if (context.Tab_Index === 1) {
        this.dwEventService.publishEvent(DwGridEvents.setQuickSearchText,
          {
            itemName: AppMetaDataItemNames.CourseModuleProgress,
            quickSearchText: ''
          });
      }

    });
  }

  clickModule(moduleProgress: StudentModuleProgressQueryEntity) {
    const module = this.courseModuleProgress.find(m => m.CourseModuleProgressId === moduleProgress.CourseModuleProgressId);
    
    if (module) {
      this.setCurrentModule(module);
    }
  }

  clickLesson(lesson: StudentLessonProgressQueryEntity) {
     // Need to set selected selected lesson and the tab
    this.formApi.patchVariableContext<CourseProgressVariableContext>({
      Tab_Index: 2
    });

    this.dwEventService.publishEvent(DwGridEvents.setQuickSearchText,
      {
        itemName: AppMetaDataItemNames.CourseModuleProgress,
        quickSearchText: lesson.CourseLessonLessonName
      }
    )
  }

  setCurrentModule(module: CourseModuleProgressEntity) {
    this.currentModule = module;
    this.formApi.patchVariableContext<CourseProgressVariableContext>({
      Selected_Module_Id: module?.CourseModuleProgressId
    });

    this.lessonListConfig.filters = {};
    this.lessonListConfig.filters[AppMetaData.Queries.StudentLessonProgress.Attributes.CourseModuleProgressId.fieldName] = this.currentModule?.CourseModuleProgressId || -1;
  }
 
  setupSection() {
    this.studentCourse = this.formApi.getFormData<StudentCourseEntity>();

    this.courseRepositoryService.getStudentCourseProgressByStudentCourse(this.studentCourse?.StudentCourseId)
      .subscribe(studentCourseProgress => {
        this.studentCourseProgress = studentCourseProgress;

        this.studentCourse = studentCourseProgress;

        this.courseModuleProgress = this.studentCourse?.CourseModuleProgress || [];

        this.setCurrentModule(this.courseModuleProgress[0]);

        this.moduleListConfig.filters = {
          StudentCourseId: this.studentCourse?.StudentCourseId
        }

        this.initComplete = true;
      });
  }

}
