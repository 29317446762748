import { Component, Inject, OnInit } from '@angular/core';
import {
  DwComponent, DwComponentType, DwExpressionService, DwFormContextMode, DwMdFormContextInfo, DwMetaDataFormApi, DwMetaDataFormStateService,
  DwMetaDataService, DwMetaDataServiceToken, DwSectionBaseComponent, DwUiConfigRegistryService, DwUIMetaDataConfig, DwUIMetaDataConfigToken,
  DwLookupSearchType, DwOrmDataService, DwOrmDataServiceToken, DwQueryHelper, DwQueryFilter, DwOperator, DwFilterType,
  isAfterDate
}
  from '@devwareapps/devware-cap';
import { AppMetaDataItemNames, CourseLessonEntity, CourseLessonProgressEntity, CourseLessonSessionEntity, LessonTypeAllItems, QuizResultEntity, VideoEntity } from '../../../../meta-data/app-meta-data.service';
import { CourseRepositoryService } from '../../services/course-repository.service';
import { CourseNavigationService } from '../../services/course-navigation.service';
import { LessonNavigationInfo } from '../../models/lesson-navigation-info.model';
import { LessonProgressHierarchy } from '../../models/lesson-progress-hierarchy.model';
import { DateTimeUtilService } from '../../../shared/util/date-time-util.service';
import { VideoEvent } from '../../../videos/models/video-event.model';
import { VideoEventType } from '../../../videos/models/video-event-type.enum';
import { QuizProgressEvent } from '../../../quizzes/models/quiz-progress-event.model';
import { LessonFlagUtilService } from '../../services/lesson-flag-util.service';
import { QuizHomeView } from '../../../quizzes/models/quiz-home-view.enum';


@DwComponent({
  key: 'lesson-ui',
  name: 'Lesson UI',
  componentType: DwComponentType.formSection,
  isGlobal: false,
  parentItemName: AppMetaDataItemNames.CourseLesson,

})
@Component({
  selector: 'app-lesson-ui',
  templateUrl: './lesson-ui.component.html',
  styleUrls: ['./lesson-ui.component.scss']
})
export class LessonUiComponent extends DwSectionBaseComponent {
  lesson: CourseLessonEntity;
  video: VideoEntity;
  formApi: DwMetaDataFormApi; 
  lessonNavigationInfo: LessonNavigationInfo;
  progress: LessonProgressHierarchy;



  videoLessonType = LessonTypeAllItems.Video;
  quizLessonType = LessonTypeAllItems.Quiz;
  currentUpdateTimeSeconds: number;
  currentTimeToAddSeconds: number;
  pageSessionStart: string;
  lastVideoStart: string;
  videoWidth: string;
  flagEnabled: boolean = false;
  flagSubmitted: boolean;
  lessonLocked: boolean = false;

  hideNavigation: boolean = false;

  constructor(
    dwExpressionService: DwExpressionService,
    protected dwUiConfigRegistryService: DwUiConfigRegistryService,
    @Inject(DwUIMetaDataConfigToken) uiMetaDataConfig: DwUIMetaDataConfig,
    @Inject(DwMetaDataServiceToken) dwMetaDataService: DwMetaDataService,
    @Inject(DwOrmDataServiceToken) private dataService: DwOrmDataService,
    private dwFormStateService: DwMetaDataFormStateService,
    private courseRepositoryService: CourseRepositoryService,
    private courseNavigationService: CourseNavigationService,
    private dateTimeUtilService: DateTimeUtilService,
    private lessonFlagUtilService: LessonFlagUtilService
  ) {
    super(dwExpressionService, dwUiConfigRegistryService, uiMetaDataConfig, dwMetaDataService);
    this.formApi = this.dwFormStateService.state.formApi;
  } 
  
  ngOnInit(): void {
    this.setupLesson();
  }

  quizViewChanged(view: QuizHomeView) {
    switch(view) {
      case QuizHomeView.quiz:
      case QuizHomeView.quizAnswers:
        this.hideNavigation = true;
        break;
      default:
        this.hideNavigation = false;
        break;
    }
  }
 
  protected setupLesson(): void {
    const lessonEntity = this.formApi.getFormData<CourseLessonEntity>();

    const courseParameters = this.courseNavigationService.getCurrentCourseParameters();

    this.courseRepositoryService.getLessonProgress(courseParameters)
      .subscribe(lessonProgress => {
        this.progress = lessonProgress;

        if(isAfterDate(lessonProgress?.course?.studentCourse?.CourseEndorsementStudent?.ExpirationDateTime)) {
          this.lessonLocked = true;
        }

        this.lesson = this.progress.lesson.lesson;

        this.setupLessonDetails()

        this.setupLessonSession();
      });

    this.courseNavigationService.getLessonNavigationInfo(courseParameters)
      .subscribe(lessonNavigationInfo => {
        this.lessonNavigationInfo = lessonNavigationInfo;
      });
  }

  flagLesson() {
    if (this.flagSubmitted) {
      return;
    }

    this.lessonFlagUtilService.showLessonFlagModal(this.progress.course.course.CourseId, this.lesson.CourseLessonId)
      .subscribe(result => {
      if (!result.cancelled) {
          this.flagSubmitted= true;
        }
      });
  }

  setupLessonDetails() {
    switch (this.lesson.LessonTypeId) {
      case LessonTypeAllItems.Video:
        this.setupVideoLesson();
        break;
      case LessonTypeAllItems.Quiz:
        this.setupQuizLesson();
        break;
    }
  }

  videoWidthChanged(width: string) {
     this.videoWidth = width;
  }

  setupQuizLesson() {

  }

  private setupVideoLesson() {
    //this.video = this.progress?.lesson?.lesson?.Video;
  }



  videoEvent(videoEvent: VideoEvent) {

    switch (videoEvent.videoEventType) {
      case VideoEventType.videoStopped:
      case VideoEventType.videoPaused:
        this.setVideoProgress(videoEvent.furtherestVideoTime, videoEvent.percentComplete);
        break;
      case VideoEventType.videoStarted:
        this.lastVideoStart = this.dateTimeUtilService.getCurrentDateTime();
        if (!this.progress.currentLessonSession.SessionStartDateTime) {
          this.progress.currentLessonSession.SessionStartDateTime = this.dateTimeUtilService.getCurrentDateTime();
        }
    }
  }

  quizEvent(quizEvent: QuizProgressEvent) {
    const dateTime = this.dateTimeUtilService.getCurrentDateTime();
    
    //console.log('Quiz Event', quizEvent);
    const currentLessonSession = this.progress.currentLessonSession;
    currentLessonSession.LessonCompleted = quizEvent.isComplete;
    currentLessonSession.QuizResultId = quizEvent.quizResult?.QuizResultId;

    currentLessonSession.SessionStartDateTime = quizEvent.quizResult?.QuizStartDateTime;
    currentLessonSession.SessionEndDateTime = quizEvent.quizResult?.QuizStopDateTime;

    this.updateSessionTime(quizEvent.quizResult?.TotalTimeSeconds);

    const lessonProgress: Partial<CourseLessonProgressEntity> = {};

    if (quizEvent.isComplete) {
      lessonProgress.LessonComplete = true;
      lessonProgress.PercentComplete = 100;
    } 
    
    lessonProgress.BestQuizResultId = quizEvent.bestQuizResultId;
    lessonProgress.LastActivityDateTime = quizEvent.quizResult?.QuizStopDateTime;
    
    this.courseRepositoryService.updateLessonProgress(this.progress, lessonProgress, this.currentTimeToAddSeconds)
      .subscribe(result => {
        this.progress = result;

        this.setupLessonSession();
      });
  }

  protected setupLessonSession() {
    this.pageSessionStart = this.dateTimeUtilService.getCurrentDateTime()

    this.progress.currentLessonSession = {
      SessionStartDateTime: null,
      SessionEndDateTime: null,
      TotalTimeSeconds: 0,

      VideoId: this.video?.VideoId,
    }
  }

  markLessonComplete() {
    this.progress.currentLessonSession.LessonCompleted = true;

    const dateTime = this.dateTimeUtilService.getCurrentDateTime();

    if (!this.progress.currentLessonSession.SessionStartDateTime) {
      this.progress.currentLessonSession.SessionStartDateTime = this.pageSessionStart;
    }

    this.progress.currentLessonSession.SessionEndDateTime = dateTime;

    this.updateSessionTime();

    this.courseRepositoryService.updateLessonProgress(this.progress, { LessonComplete: true, LastActivityDateTime: dateTime }, this.currentTimeToAddSeconds)
      .subscribe(result => {
        this.progress = result;
      })
  }

  setVideoProgress(furtherestVideoTime: number, percentComplete: number) {
    const lessonProgress: Partial<CourseLessonProgressEntity> = {};

    this.progress.currentLessonSession.LessonCompleted = (percentComplete >= 100);
    this.progress.currentLessonSession.VideoFurthestTimeSeconds = furtherestVideoTime;

    lessonProgress.LessonComplete = this.progress.currentLessonSession.LessonCompleted;

    if (percentComplete > 0) {
      lessonProgress.PercentComplete = percentComplete;
    }

    if (!lessonProgress.VideoFurthestTimeSeconds || lessonProgress.VideoFurthestTimeSeconds < furtherestVideoTime) {
      lessonProgress.VideoFurthestTimeSeconds = furtherestVideoTime;
    }
    const dateTime = this.dateTimeUtilService.getCurrentDateTime();

    lessonProgress.LastActivityDateTime = dateTime;
    this.progress.currentLessonSession.SessionEndDateTime = dateTime;

    const currentVidoeViewTimeSeconds = this.dateTimeUtilService.calcDurationSeconds(this.lastVideoStart, this.progress.currentLessonSession.SessionEndDateTime, 0);

    let previousSeconds = this.progress.currentLessonSession.TotalTimeSeconds || 0;

    let newTotalTimeSeconds = previousSeconds + currentVidoeViewTimeSeconds;

    this.updateSessionTime(newTotalTimeSeconds);

    this.courseRepositoryService.updateLessonProgress(this.progress, lessonProgress, this.currentTimeToAddSeconds)
      .subscribe(result => {
        this.progress = result;
      })
  }


  private updateSessionTime(newTotalSeconds?: number) {
    let previousSeconds = this.progress.currentLessonSession.TotalTimeSeconds || 0;

    if (newTotalSeconds) {
      this.progress.currentLessonSession.TotalTimeSeconds = newTotalSeconds;
    } else {
      this.progress.currentLessonSession.TotalTimeSeconds = this.dateTimeUtilService.calcDurationSeconds(this.progress.currentLessonSession.SessionStartDateTime, this.progress.currentLessonSession.SessionEndDateTime, 0);
    }

    this.currentTimeToAddSeconds = this.progress.currentLessonSession.TotalTimeSeconds - previousSeconds;
  }


  updateLessonProgress() {

  }

}

