<div style="max-width:1200px">
    <dw-button-bar>
        <button class="btn btn-warning" (click)="setThumbnailTime()">Update Thumbnail</button>
        <button class="btn btn-warning" (click)="replaceVideoModal()">Replace Video</button>
        <button class="btn btn-success" (click)="refreshVideo()">Refresh Video</button>
        <button class="btn btn-danger" (click)="close()">Close</button>
    </dw-button-bar>
</div>



<app-video-host *ngIf="videoEntity" [video]="videoEntity" (videoEvent)="videoEvent($event)"></app-video-host>