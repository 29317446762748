<div dwRow [hidden]="isFSAdmin">
    <div dwCol="6" class="pending-load-id">You must be Flight School Admin to configure payments!</div>
</div>
<div dwRow [hidden]="model?.customerId != undefined">
    <div dwCol="6" class="pending-load-id">Loading Payment Processor Customer ID...</div>
    <br/>
</div>
<form id="payment-form" [formGroup]="formGroup" (ngSubmit)="onSubmit()">
    <div dwRow [hidden]="model?.customerId != undefined">
        <div dwCol="6" class="pending-load-id">Loading Payment Processor Customer ID...</div>
        <br/>
    </div>
    <div dwRow [hidden]="!isFSAdmin">
        <div dwCol="9" class="payment-label">New Payment Details</div>  
        <div dwCol="3">
            <button id="submit" type="submit"  [disabled]="!paymentReady" class="btn-sm create-card-submit">
                Add Method
            </button>
        </div>
    </div>
    <div dwRow [hidden]="!isFSAdmin">
        <div dwCol="5" class="name-label">
            Name on Card&nbsp;<span class="pending-load-id">*</span>
        </div>
        <div dwCol="3" class="name-label">
            Billing Email&nbsp;<span class="pending-load-id">*</span>
        </div>
        <div dwCol="4">
            <span class="card-default">
                <input type="checkbox" class="check-input" [disabled]="alreadyDefault == true" 
                [(ngModel)]="makeDefault" id="makeDefault" formControlName="makeDefault" />
                Is Default
            </span>
        </div>
    </div>
    <div dwRow [hidden]="!isFSAdmin">
        <div dwCol="5" class="">
            <input type="text" id="nameOnCard" class="nameInput" (ngModelChange)="onChange($event)" 
            [(ngModel)]="nameOnCard" formControlName="nameOnCard" required>
        </div>
        <div dwCol="5" class="">
            <input type="text" id="billingEmail" class="emailInput" (ngModelChange)="onChange($event)" 
            [(ngModel)]="billingEmail" formControlName="billingEmail" required>
        </div>
    </div>
    <div dwRow [hidden]="!isFSAdmin">
        <div dwCol="12">
            <div id="link-authentication-element"></div>
            <div id="payment-element"></div>
        </div>
    </div>
</form>
<!-- <form [formGroup]="formGroup" #addCard="ngForm" (ngSubmit)="onSubmit()">
    <div dwRow>
        <div dwCol="2" class="">
            <button (click)="resetCard()" type="button" class="btn-sm card-reset">Reset</button>
        </div>
        <div dwCol="4"></div>
    </div>
</form> -->
