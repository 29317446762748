import { Inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { DwOrmDataServiceToken, DwOrmDataService, DwMetaDataServiceToken, DwMetaDataService, DwSecurityUserService, DwCacheService, DwEventService, DwConfigService, DwConfigServiceToken } from "@devwareapps/devware-cap";
import { Observable, of } from "rxjs";
import { QuizEntity, AppMetaDataItemNames, FlightSchoolGroupEntity, FlightSchoolGroupWithStudentCourseDetailQueryEntity, AppMetaData, StudentCourseEntity, PackageEntity, RoUserRoleDetailEntity, JoinRequestEntity, JoinRequestEmailEntity, FlightSchoolEntity, StandardColorSetEntity, ReferenceStatusAllItems } from "../../../meta-data/app-meta-data.service";
import { map, mergeMap, tap } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { ResetStudentAssignmentResult } from "../models/reset-student-assignment-result.model";
import { ResetStudentAssignmentRequest } from "../models/reset-student-assignment-request.model";
import { StudentCourseProgressResult } from "../models/student-course-progress-result.model";
import { StudentCourseProgressRequest } from "../models/student-course-progress-request.model";
import { DateTimeUtilService } from "../../shared/util/date-time-util.service";
import { StudentChangeSchoolRequest } from "../models/student-change-school-request.model";
import { StudentChangeSchoolResult } from "../models/student-change-school-result.model";

@Injectable({
    providedIn: 'root'
})
export class SchoolRepositoryService {
    
    studentProgressApi: string;
    schoolAdminApi: string;

    constructor(@Inject(DwOrmDataServiceToken) private dwOrmDataService: DwOrmDataService,
        @Inject(DwMetaDataServiceToken) private dwMetaDataService: DwMetaDataService,
        @Inject(DwConfigServiceToken) private configService: DwConfigService,
        private dwSecurityUserService: DwSecurityUserService,
        private dwCacheService: DwCacheService,
        private dwEventService: DwEventService,
        private router: Router,
        private http: HttpClient,
        private dateTimeUtilServie: DateTimeUtilService,
    ) {
        this.studentProgressApi = `${configService.coreConfig.apiRoot}/aviator-online/students/progress`;
        this.schoolAdminApi = `${configService.coreConfig.apiRoot}/aviator-online/school/admin`;
    }

    getFlightSchool(flightSchoolId: any): Observable<FlightSchoolEntity> {
        const query = AppMetaData.FlightSchool.CreateQueryBuilder();

        query.query.FieldSettings.LoadAllLookupDisplayFields = false;

        query.addFilterAnd(f => f.Equal(a => a.FlightSchoolId, flightSchoolId));

        query.addPrefetch(r => r.FlightSchoolBranding);

        return this.dwOrmDataService.executeQuerySingle(query.query);
    }

    getCurrentSchoolId(): Observable<number> {
        const query = AppMetaData.UserDetail.CreateQueryBuilder();

        const userId = this.dwSecurityUserService.securityState.securityContext?.ApplicationUser?.UserId;

        if (!userId) {
            return of(null);
        }

        query.addFilterAnd(f => f.Equal(a => a.UserId, userId));

        return this.dwOrmDataService.executeQuerySingle(query.query)
            .pipe(map((schoolUser) => {
                return schoolUser?.FlightSchoolId
            }));
        ;
    }

    resendJoinRequest(joinRequestId: number): Observable<any> {
        if (!joinRequestId) {
            return;
        }

        return this.loadJoinRequest(joinRequestId)
            .pipe(mergeMap(joinRequest => {
                return this.resendJoinRequestEmails(joinRequest.JoinRequestEmail)
            }));
    }

    resendJoinRequestEmails(emails: JoinRequestEmailEntity[]): Observable<any> {
        const emailsToSave: JoinRequestEmailEntity[] = [];
        for (let email of emails) {
            if (!email.Accepted)
                email.LastResendRequestDateTime = this.dateTimeUtilServie.getCurrentDateTime();

            emailsToSave.push(email);
        }

        if (emailsToSave.length==0) {
            return of();
        }

        return this.dwOrmDataService.saveEntityCollection(emailsToSave);
    }


    loadJoinRequest(joinRequestId: number): Observable<JoinRequestEntity> {
        const query = AppMetaData.JoinRequest.CreateQueryBuilder();

        query.query.FieldSettings.LoadAllLookupDisplayFields = false;

        query.addFilterAnd(filters => filters.Equal(f => f.JoinRequestId, joinRequestId));

        query.addPrefetch(r => r.JoinRequestEmail);

        return this.dwOrmDataService.executeQuerySingle(query.query);
    }

    getUserRoleDetaill(userId: number): Observable<RoUserRoleDetailEntity> {
        const query = AppMetaData.RoUserRoleDetail.CreateQueryBuilder();

        query.query.FieldSettings.LoadAllLookupDisplayFields = false;

        query.addFilterAnd(filters => filters.Equal(f => f.UserId, userId || -1));

        return this.dwOrmDataService.executeQuerySingle(query.query);
    }

    getStudentCourses(studentId: number): Observable<StudentCourseEntity[]> {
        const query = AppMetaData.StudentCourse.CreateQueryBuilder();


        query.addFilterAnd(f => f.Equal(a => a.StudentId, studentId));

        return this.dwOrmDataService.executeQuery(query.query);
    }

    getCoursePackages(): Observable<PackageEntity[]> {
        const query = AppMetaData.Package.CreateQueryBuilder();

        query.addPrefetch(r => r.PackageCourse);

        return this.dwOrmDataService.executeQuery(query.query);
    }

    assignCourses(studentId: number, courseIds: number[]): Observable<StudentCourseEntity[]> {
        const studentCourses: StudentCourseEntity[] = [];

        return this.getStudentCourses(studentId)
            .pipe(mergeMap(existingCourses => {

                for (const courseId of courseIds) {

                    const currentExistingCourses = existingCourses.filter(c => c.CourseId == courseId);

                    if (currentExistingCourses.length > 0) {
                        const existingActiveCourse = currentExistingCourses.find(c => c.StudentCourseStatusId == ReferenceStatusAllItems.Active);

                        if (existingActiveCourse) {
                            continue;
                        }

                        // Otherwise, they have an inactive course, so we need to reactivate it

                        const existingInactiveCourse = currentExistingCourses[0];

                        existingInactiveCourse.StudentCourseStatusId = ReferenceStatusAllItems.Active;
                        studentCourses.push(existingInactiveCourse);
                        continue;
                    }

                    const newStudentCourse: StudentCourseEntity = {
                        _itemName: AppMetaDataItemNames.StudentCourse,
                        StudentId: studentId,
                        CourseId: courseId,
                    }

                    studentCourses.push(newStudentCourse);
                }

                if (studentCourses.length == 0) {
                    return of(studentCourses);
                }

                return this.dwOrmDataService.saveEntityCollection(studentCourses, false, false)
                    .pipe(tap(results => {
                        // We need to trigger that student was updated so related grids are updated (Course progress grid is based on student and not student course so won't update normally)
                        this.dwEventService.publishEvent(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaData.Student.ItemDetail.itemName), {});
                    }));
            }));
    }

    /** Gets a stuent group with course details */
    getStudentGroup(flightSchoolGroupId: number, courseId: number, flightSchoolId: number): Observable<FlightSchoolGroupWithStudentCourseDetailQueryEntity[]> {

        return AppMetaData.Queries.FlightSchoolGroupWithStudentCourseDetail.CreateQueryBuilder(this.dwMetaDataService)
            .pipe(mergeMap(query => {

                query.addFilterAnd(f => f.Equal(a => a.FlightSchoolGroupId, flightSchoolGroupId));
                query.addFilterAnd(f => f.Equal(a => a.StudentCourseCourseId, courseId));
                query.addFilterAnd(f => f.Equal(a => a.StudentCourseIsActive, true));

                // Map 2 fligh school ids (one for the group and one for the student)
                query.addFilterAnd(f => f.Equal(a => a.FlightSchoolId, flightSchoolId));

                return this.dwOrmDataService.executeQuery(query.query);
            }));
    }

    resetStudentAssignmentProgress(request: ResetStudentAssignmentRequest): Observable<ResetStudentAssignmentResult> {
        const url = `${this.studentProgressApi}/reset-student-assignment-progress`;

        return this.http.post<ResetStudentAssignmentResult>(url, request);
    }

    studentChangeSchool(request: StudentChangeSchoolRequest): Observable<StudentChangeSchoolResult> {
        const url = `${this.schoolAdminApi}/student-change-school`;

        return this.http.post<StudentChangeSchoolResult>(url, request)
            .pipe(tap(result => {

                if (result.IsSuccess){
                     this.dwEventService.publishEvent(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaData.DwUser.ItemDetail.itemName), {});
                     this.dwEventService.publishEvent(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaData.UserDetail.ItemDetail.itemName), {});
                     this.dwEventService.publishEvent(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaData.Student.ItemDetail.itemName), {});
                     this.dwEventService.publishEvent(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaData.Assignment.ItemDetail.itemName), {});
                }

            }))
    }


    resetStudentCourseProgress(request: StudentCourseProgressRequest): Observable<StudentCourseProgressResult> {
        const url = `${this.studentProgressApi}/reset-student-course-progress`;

        return this.http.post<StudentCourseProgressResult>(url, request).pipe(map(result => {
            if (result.IsSuccess) {
                // We need to trigger that student was updated so related grids are updated (Course progress grid is based on student and not student course)
                this.dwEventService.publishEvent(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaData.Student.ItemDetail.itemName), {});
            }

            return result;
        }))
    }

    updateStudentCourseProgress(request: StudentCourseProgressRequest): Observable<StudentCourseProgressResult> {
        const url = `${this.studentProgressApi}/update-student-course-progress`;

        return this.http.post<StudentCourseProgressResult>(url, request).pipe(map(result => {
            if (result.IsSuccess) {
                // We need to trigger that student was updated so related grids are updated (Course progress grid is based on student and not student course)
                this.dwEventService.publishEvent(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaData.Student.ItemDetail.itemName), {});
            }

            return result;
        }))
    }

    updateCurrentStudentCourseProgress(courseId: number): Observable<StudentCourseProgressResult> {
        const url = `${this.studentProgressApi}/update-current-student-course-progress?courseId=${courseId}`;

        return this.http.post<StudentCourseProgressResult>(url, {});
    }

    updateAllCourseProgress(courseId: number): Observable<StudentCourseProgressResult> {
        const url = `${this.studentProgressApi}/update-all-course-progress?courseId=${courseId}`;

        return this.http.post<StudentCourseProgressResult>(url, {});
    }

    deleteStudentCourse(request: StudentCourseProgressRequest): Observable<StudentCourseProgressResult> {
        const url = `${this.studentProgressApi}/delete-student-course`;

        return this.http.post<StudentCourseProgressResult>(url, request)
            .pipe(map(result => {
                if (result.IsSuccess) {
                    // We need to trigger that student was updated so related grids are updated (Course progress grid is based on student and not student course)
                    this.dwEventService.publishEvent(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaData.Student.ItemDetail.itemName), {});
                }

                return result;
            }))
    }

    getColorSets() : Observable<StandardColorSetEntity[]> {
        const cacheInvalidationTopics: string[] = [];

        cacheInvalidationTopics.push(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaData.StandardColorSet.ItemDetail.itemName));

        return this.dwCacheService.get('colorSets', this.loadColorSets(), null, cacheInvalidationTopics);
    }

    private loadColorSets() {
        const query = AppMetaData.StandardColorSet.CreateQueryBuilder();
    
        return this.dwOrmDataService.executeQuery(query.query);
      }
}