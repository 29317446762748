import { Injectable, Optional } from "@angular/core";
import { DwActionHandler, DwModalService, DwMenuService, DwMetaDataGridActionState, DwActionResult, DwAction, DwActionType, DwModalConfig, DwImportMetaDataModalComponent, DwModalButtonStandardConfigs, DwGridActionResult, DwRoutingService, DwModalSize, ensureArray, DwMetaDataFormStateService, DwMetaDataFormApi, DwEventService, DwGridEvents, DwActionState, DwMdFormActionState, DwUserEntity, DwMdFormActionResult } from "@devwareapps/devware-cap";
import { Observable, of } from "rxjs";
import { AppMetaData, AppMetaDataItemNames, AssignmentStudentEntity } from "../../../meta-data/app-meta-data.service";
import { map } from "rxjs/operators";
import { CourseProgressVariableContext } from "../../courses/models/course-progress-variable-context.model";
import { AppPermissions } from "../../../meta-data/app-permissions.enum";
import { StudentChangeSchoolModalComponent } from "../components/student-change-school-modal/student-change-school-modal.component";

@DwAction({
    keyName: 'student-change-school-action',
    display: 'Student Change School Modal',
    actionType: DwActionType.formAction,
    parentItemName: AppMetaDataItemNames.DwUser,
    isGlobal: false,
    defaultButtonConfig: {
        useButtonDefaults:true,
        useParentDisplay: true,
        buttonClass: 'btn btn-warning',
        iconClass: 'fa fa-user-times',
    },
    defaultSecurityConfig: {
        requireAuthentication: true,
        useEmulatedPermissions: false,
        requireCanUpdate: true,
        //permissionMeaning: AppPermissions.lMSSetupAdmin,
    }
})
@Injectable()
export class StudentChangeSchoolActionService implements DwActionHandler {
    constructor(
        private dwModalService: DwModalService,
        private dwMenuService: DwMenuService,
        private dwRoutingService: DwRoutingService,
        @Optional() private dwFormStateService: DwMetaDataFormStateService,
        private dwEventService: DwEventService
    ) {
    }

    handleAction(actionState: DwMdFormActionState<any>): Observable<DwActionResult> {
        return this.showModal(actionState);
    }

    private showModal(actionState: DwMdFormActionState<any>): Observable<DwMdFormActionResult> {

        const user = actionState.formApi.getFormData<DwUserEntity>();

        let modalConfig = new DwModalConfig();

        modalConfig.buttons = DwModalButtonStandardConfigs.instance.okAndClose;
        modalConfig.data = user.UserId;
        modalConfig.allowFullScreen = true;
        modalConfig.modalSize = DwModalSize.medium;

        modalConfig.component = StudentChangeSchoolModalComponent;

        modalConfig.title = actionState.actionPreference.actionDisplay;

        return this.dwModalService.showModal<any,any>(modalConfig)
            .pipe(map(modalResult => {
                const result : DwMdFormActionResult = {
                    success: true,
                    cancelled: false,
                    returnAfter: true,
                }
                
                if (result.cancelled) {
                    result.cancelled = true;
                    result.returnAfter = false;

                    return result;
                }

                result.success = true;
                result.message = 'School changed successfully';
                
                return result;
            }));
    }
}