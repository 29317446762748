import { Injectable } from "@angular/core";
import { DwSecurityStateService } from "@devwareapps/devware-cap";


@Injectable({ providedIn: 'root' })
export class InactivityUtilService {

    constructor(private dwSecurityStateService: DwSecurityStateService) { }
    
    extendedInactivitytime() {
        this.dwSecurityStateService.setLastUserInteraction();
    }
}