import { Injectable } from "@angular/core";
import { VideoEvent } from "../models/video-event.model";
import { VideoEventType } from "../models/video-event-type.enum";
import { BufferItem, VideoBufferingInfo } from "../models/video-buffering-info.model";
import { DateTimeUtilService } from "../../shared/util/date-time-util.service";
import { DwLoggingService } from "@devwareapps/devware-cap";
import { VideoEntity } from "../../../meta-data/app-meta-data.service";


@Injectable({
    providedIn: 'root'
})
export class VideoBufferingUtilService {
    bufferingThresholdSeconds: number = 2;

    constructor(private dateTimeUtilService: DateTimeUtilService,
        private dwLoggingService: DwLoggingService
    ) {
    }

    loggingEnabled: boolean = true;

    videoBuffingInfo: VideoBufferingInfo = {
        bufferingItems: []
    };

    currentBufferInfo: BufferItem;

    private initBufferInfo() {
        this.videoBuffingInfo = {
            bufferingItems: []
        };
        this.currentBufferInfo = null;
    }

    public handleBufferingEvent(video: VideoEntity, videoEvent: VideoEvent) {
        switch (videoEvent.videoEventType) {
            case VideoEventType.videoStarted:
                this.initBufferInfo();
                this.videoBuffingInfo.startUpbufferTime = {
                    lastUpdateDateTime: new Date().toISOString(),
                    lastUpdateTimeSeconds: 0
                }
                break;
            case VideoEventType.videoPaused:
            case VideoEventType.videoStopped:

                if (this.videoBuffingInfo?.bufferingItems?.length > 0) { 
                    this.writeLogMessage(video, ` ${this.videoBuffingInfo.bufferingItems.length} Buffering Issues`, this.videoBuffingInfo.bufferingItems.reduce((a, b) => a + b.bufferingTimeSeconds, 0));
                }

                this.initBufferInfo();
                break;
            case VideoEventType.videoProgress:

                if (!this.videoBuffingInfo) {
                    break;
                }

                // Handle Startup Buffering Time
                if (this.videoBuffingInfo.startUpbufferTime?.lastUpdateDateTime && !this.videoBuffingInfo.startUpbufferTime.currentUpdateDateTime) {
                    this.handleStartupBufferingTime(video, videoEvent);
                    break;
                }

                if (this.currentBufferInfo) {
                    this.currentBufferInfo.currentUpdateDateTime = new Date().toISOString();
                    this.currentBufferInfo.currentUpdateTimeSeconds = videoEvent.videoTime;


                    const actualSecondsPassed = this.dateTimeUtilService.calcDurationSeconds(this.currentBufferInfo.lastUpdateDateTime, this.currentBufferInfo.currentUpdateDateTime);

                    const playerSecondsPassed = this.currentBufferInfo.currentUpdateTimeSeconds - this.currentBufferInfo.lastUpdateTimeSeconds;

                    // console.log(`paymentSecondsPassed: ${playerSecondsPassed}, actualSecondsPassed: ${actualSecondsPassed}`, this.currentBufferInfo);

                    this.currentBufferInfo.bufferingTimeSeconds = actualSecondsPassed - playerSecondsPassed;

                    if (this.currentBufferInfo.bufferingTimeSeconds >= this.bufferingThresholdSeconds) {
                        this.videoBuffingInfo.bufferingItems.push(this.currentBufferInfo);

                      //  this.writeLogMessage(video, 'Buffering Time', this.currentBufferInfo.bufferingTimeSeconds);

                        this.currentBufferInfo = null;
                    }
                }

                if (!this.currentBufferInfo) {
                    this.currentBufferInfo = {
                        lastUpdateDateTime: new Date().toISOString(),
                        lastUpdateTimeSeconds: videoEvent.videoTime
                    }
                } else {
                    this.currentBufferInfo.currentUpdateDateTime = new Date().toISOString();
                    this.currentBufferInfo.currentUpdateTimeSeconds = videoEvent.videoTime;
                }


                break;

        }
    }

    private handleStartupBufferingTime(video: VideoEntity, videoEvent: VideoEvent) {
        // Handle Startup Buffering Time
        this.videoBuffingInfo.startUpbufferTime.currentUpdateDateTime = new Date().toISOString();
        this.videoBuffingInfo.startUpbufferTime.currentUpdateTimeSeconds = videoEvent.videoTime;

        this.videoBuffingInfo.startUpbufferTime.bufferingTimeSeconds = this.dateTimeUtilService.calcDurationSeconds(this.videoBuffingInfo.startUpbufferTime.lastUpdateDateTime, this.videoBuffingInfo.startUpbufferTime.currentUpdateDateTime);

        // this.dwLoggingService.logToConsole('Buffering Startup Time: ' + this.videoBuffingInfo.startUpbufferTime.bufferingTimeSeconds);

        if (this.videoBuffingInfo.startUpbufferTime.bufferingTimeSeconds >= this.bufferingThresholdSeconds) {
            this.writeLogMessage(video, 'Startup Time', this.videoBuffingInfo.startUpbufferTime.bufferingTimeSeconds);
        }
    }

    writeLogMessage(video: VideoEntity, messageDetails: string, bufferingTimeSeconds: number) {
        const message = `Video Log: ${video.VideoName} (${video.VideoId}) - ${messageDetails} ${bufferingTimeSeconds.toFixed(1)} seconds`;

        this.dwLoggingService.logWarning(message, null, true);
    }

}
