import { Component, OnDestroy, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { Observable, combineLatest, of, Subscription } from 'rxjs';
import { map, mergeMap } from "rxjs/operators";
import { ValidatorFn, AbstractControl, FormGroup, FormControl } from "@angular/forms";
import { DwConfigServiceToken, DwConfigService, DwSecurityUserService, DwComponent, DwComponentType, DwMetaDataService,
         DwSectionBaseComponent, DwExpressionService, DwUiConfigRegistryService, DwUIMetaDataConfig,
         DwMetaDataFormApi,
         DwUIMetaDataConfigToken, DwMetaDataServiceToken, DwMetaDataFormStateService, DwFormActionInterceptor } 
        from '@devwareapps/devware-cap';
import { AviatorUser } from '../../../shared/models/aviator-user.model';
import { Inject, Injectable } from "@angular/core";
import { AppMetaDataItemNames, ProcessorConfigEntity, PaymentMethodEntity  } 
        from '../../../../meta-data/app-meta-data.service';
import { PaymentsRepositoryService } from "src/app/features/payments/services/payments-repository.service";
import { HttpClient } from "@angular/common/http";
import { CreateCustomerResult } from "src/app/features/payments/models/create-customer-result.model";
import { DateTimeUtilService } from '../../../shared/util/date-time-util.service';

import { environment } from 'src/environments/environment';
import { EnvironmentConfigItem } from 'src/environments/models/environment-config.model';
import { AppPermissions } from 'src/app/meta-data/app-permissions.enum';

@DwComponent({
  key: 'payments-create-payment',
  name: 'Payments (Add Card)',
  componentType: DwComponentType.formSection,
  isGlobal: false,
  parentItemName: [ AppMetaDataItemNames.ProcessorConfig ]
})
@Component({
  selector: 'app-payments-create-payment',
  templateUrl: './payments-create-payment.component.html',
  styleUrls: ['./payments-create-payment.component.scss']
})
export class PaymentsCreatePaymentComponent extends DwSectionBaseComponent 
  implements OnInit, OnDestroy {

    subscriptions: Subscription[] = [];
  aviatorUser: AviatorUser;
  paymentsApi: string;

  constructor(
    dwExpressionService: DwExpressionService,
    protected dwUiConfigRegistryService: DwUiConfigRegistryService,
    @Inject(DwUIMetaDataConfigToken) uiMetaDataConfig: DwUIMetaDataConfig,
    @Inject(DwMetaDataServiceToken) dwMetaDataService: DwMetaDataService,
    private dwFormStateService: DwMetaDataFormStateService,
    private dwSecurityUserService: DwSecurityUserService,
    private paymentsRepoSvc: PaymentsRepositoryService,
    private dateTimeUtilService: DateTimeUtilService,
    private http: HttpClient,
    @Inject(DwConfigServiceToken) private configService: DwConfigService) 
  {
    super(dwExpressionService, dwUiConfigRegistryService, uiMetaDataConfig, dwMetaDataService);
    this.paymentsApi = `${configService.coreConfig.apiRoot}/aviator-online/Payments`;
    this.formApi = this.dwFormStateService.state.formApi;
  }

  formApi: DwMetaDataFormApi;
  stripe : any;
  model: any = { customerId: undefined, billing: { name: undefined, email: undefined } }
  isFSAdmin: boolean = false;
  customerId: string = null;
  test : boolean = false;
  nameOnCard:string = undefined;
  cardNotReady : boolean = true;
  card: any;
  currentError: string = undefined;
  cardError: string = undefined;
  alreadyDefault: boolean;
  makeDefault: boolean;
  processorConfig: ProcessorConfigEntity = undefined;
  
  setupComponentComplete(){
    this.formGroup = new FormGroup({
      nameOnCard: new FormControl(),
      makeDefault: new FormControl(),
    });
  }
  ngOnInit() {
    this.test = this.isTest();
    this.stripe = (globalThis as any).stripe;

    const user = this.dwSecurityUserService.securityContext.ApplicationUser;
    this.aviatorUser = new AviatorUser(user, null);

    this.isFSAdmin = this.aviatorUser?.user.Permissions.indexOf(AppPermissions.flightSchoolAdmin) > -1;
    if (!this.isFSAdmin)
      return;
    else
      this.retrieveProcessorConfig();

    this.setupCardElement();
  }
  onNameChange(cardName: string) { this.isReady(this.card?.complete, cardName);}

  isReady(complete?:boolean, name?: string, err?: string){
    const badNameErr = 'The name on the card must be provided.';
    const badName = (name ?? '').length == 0;
    if (badName && err == undefined) err = badNameErr;

    this.nameOnCard = name;
    this.currentError = err ?? this.cardError;
    this.cardNotReady = complete != true || badName;
    if (this.cardNotReady == false)
      this.currentError = undefined;
  }

  setupCardElement(){
    const style = {
      base: {
        color: '#32325d',    
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased', fontSize: '16px',
        '::placeholder': { color: '#aab7c4' }
      },
      complete: { color: '#4caf50' },
      invalid: { color: '#fa755a', iconColor: '#fa755a' }
    };
    const elements = this.stripe.core.elements();
    this.card = elements.create('card', { style: style });
    this.card.mount('#card-element');
    this.card.on('change', (event) => {
      this.hasCardError(event.error?.message,event.complete);
    });
  }

  resetCard(){
    this.card.clear();
    this.cardError = null;
    document.getElementById('card-errors').textContent = '';
  }
  hasCardError(cardError?: string, complete?: boolean){
    this.cardError = cardError;
    this.isReady(complete, this.nameOnCard, cardError);
  }

  retrieveProcessorConfig(){
    const u = this.aviatorUser?.user;
    const email = u?.Email;
    const name = `${u?.FirstName} ${u?.LastName}`;
    this.paymentsRepoSvc.getProcessorConfig(this.aviatorUser.FlightSchoolId)
        .pipe(mergeMap(cfg =>{
          if (cfg != undefined) 
            return of(cfg);
          
          const url = `${this.paymentsApi}/create-customer`;
          return this.http
              .post<CreateCustomerResult>(url, { Email: email, Name: name })
              .pipe(mergeMap(resp => {
                let newCfg: ProcessorConfigEntity = {
                  _itemName: AppMetaDataItemNames.ProcessorConfig,
                  FlightSchoolId: this.aviatorUser.FlightSchoolId,
                  CustomerId: resp.Id,
                };
                return this.paymentsRepoSvc.saveConfig(newCfg);
              }));
        }))
        .subscribe(cfg => {
          this.processorConfig = cfg;
          this.model.customerId = cfg.CustomerId;
          //this.model.billing.name = cfg.BillingName;
          //this.nameOnCard = cfg.BillingName;
          //this.model.billing.email = cfg.BillingEmail;
        });
  }

  onSubmit(){
    const asyncFunc = async () => {
      const { paymentMethod, error } = await this.stripe.core.createPaymentMethod({
        type: 'card', card: this.card,
        billing_details: {
            name: this.nameOnCard,
            //email: this.processorConfig.BillingEmail,
        },
      });

      if (error) 
        this.hasCardError(error, false);
      else {
        this.resetCard();
        let pm: PaymentMethodEntity = {
          _itemName: AppMetaDataItemNames.PaymentMethod,
          ProcessorConfigId: this.processorConfig.ProcessorConfigId,
          PaymentId: paymentMethod.id,
          //Last4: paymentMethod.card.last4,
          IsDefault:this.makeDefault
        };
        this.paymentsRepoSvc.savePayment(pm)
            .subscribe(pm => {
              const url = `${this.paymentsApi}/add-payment-method/${pm.PaymentId}`;
              this.http.get(url)
                  .subscribe(resp =>{
                    this.formApi.patchFormData({});
                  });
            });
      }
    };
    asyncFunc();
  }

  ngOnDestroy(): void {
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  isTest() : boolean {
    const environments : EnvironmentConfigItem[] = (environment as any).environments;
    if (environments) {
      const environmentKey = (environment as any).environmentKey;
      return environmentKey == 'Test' || environmentKey == 'TestLocal';
    }
    return false;
  }

}