import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, Type } from '@angular/core';
import { VideoEntity, VideoLibraryEntity, VideoStreamingProviderAllItems, VideoStreamingProviderEntity } from '../../../../meta-data/app-meta-data.service';
import { VideoRepositoryService } from '../../services/video-repository.service';
import { VideoComponentBase } from '../../models/video-component.base.interface';
import { VideoPlayerBunnyComponent } from '../streaming-providers/bunny/components/video-player-bunny/video-player-bunny.component';
import { VideoEvent } from '../../models/video-event.model';
import { VideoPlayerApiVideoComponent } from '../streaming-providers/apivideo/components/video-player-apivideo/video-player-apivideo.component';
import { VideoEventType } from '../../models/video-event-type.enum';
import { VideoBufferingUtilService } from '../../services/video-buffering-util.service';

@Component({
  selector: 'app-video-host',
  templateUrl: './video-host.component.html',
  styleUrls: ['./video-host.component.scss']
})
export class VideoHostComponent implements OnInit, OnChanges, VideoComponentBase {
  @Input() video: VideoEntity;
  @Input() furthestWatchedTime: number = 0;
  videoStreamingProvider: VideoStreamingProviderEntity;
  videoLibrary: VideoLibraryEntity;

  @Output() videoEvent = new EventEmitter<VideoEvent>();
  @Output() videoWidthChanged = new EventEmitter<string>();
  videoComponentInstance: VideoComponentBase;
  videoComponentType: Type<any>;
  videoEventSubscription: any;

  constructor(private videoRepositoryService: VideoRepositoryService,
    private videoBufferingUtilService: VideoBufferingUtilService

  ) { }

  ngOnInit(): void {
    this.setupVideo();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.video?.firstChange) {
      this.setupVideo();
    }
  }

  setupVideo(): void {
    this.loadVideoDependencies();
  }

  private loadVideoDependencies() {
    if (!this.video) {
      return;
    }

    this.videoRepositoryService.getStreamingProvider(this.video.VideoStreamingProviderId)
      .subscribe(videoStreamingProvider => {
        this.videoStreamingProvider = videoStreamingProvider;
        this.videoLibrary = this.videoRepositoryService.getVideoLibrary(videoStreamingProvider, this.video.VideoLibraryId);

        this.determiineVideoComponentType();
      });
  }

  private determiineVideoComponentType() {
    switch (this.videoStreamingProvider.VideoStreamingProviderId) {
      case VideoStreamingProviderAllItems.BunnyNet:
        this.videoComponentType = VideoPlayerBunnyComponent;
        break;
      case VideoStreamingProviderAllItems.ApiVideo:
        this.videoComponentType = VideoPlayerApiVideoComponent;
        break;
    }
  }


  configureVideoComponent(componentInstance: VideoComponentBase) {
    this.videoComponentInstance = componentInstance;

    this.videoComponentInstance.video = this.video;
    this.videoComponentInstance.videoLibrary = this.videoLibrary;
    this.videoComponentInstance.videoStreamingProvider = this.videoStreamingProvider;
    this.videoComponentInstance.furthestWatchedTime = this.furthestWatchedTime;

    this.videoEventSubscription =
      this.videoComponentInstance.videoEvent.subscribe((videoEvent: VideoEvent) => {
        if (videoEvent.videoDuration > 0) {
          videoEvent.percentComplete = videoEvent.furtherestVideoTime / videoEvent.videoDuration * 100;

          if (videoEvent.percentComplete > 99) {
            videoEvent.percentComplete = 100;
          }
        }

        this.videoBufferingUtilService.handleBufferingEvent(this.video, videoEvent);

        this.videoEvent.emit(videoEvent);
      });

    this.videoComponentInstance.setupVideo();

    this.videoComponentInstance.videoWidthChanged.subscribe(width => {
      this.videoWidthChanged.emit(width);
    });
  }

}
