import { Component, OnInit } from '@angular/core';
import { StudentDashboardComponent } from '../../../students/components/student-dashboard/student-dashboard.component';
import { SchoolRepositoryService } from '../../services/school-repository.service';
import { CourseRepositoryService } from '../../../courses/services/course-repository.service';

@Component({
  selector: 'app-school-dashboard',
  templateUrl: './school-dashboard.component.html',
  styleUrls: ['./school-dashboard.component.scss']
})
export class SchoolDashboardComponent
  // School Users are also students so we inherit from the StudentDashboardComponent
  extends StudentDashboardComponent implements OnInit {

  studentFilters: any = {};
  schoolId: number;

  constructor(private schoolRepositoryService: SchoolRepositoryService, courseRepositoryService: CourseRepositoryService) {
    super(courseRepositoryService);
  }


  ngOnInit(): void {
    super.ngOnInit();
    
    this.schoolRepositoryService.getCurrentSchoolId()
      .subscribe(schoolId => {
        this.schoolId = schoolId;

        this.setupFilters();
      });
      this.setupFilters();
  }

  setupFilters() {
    this.studentFilters = {
      UserDetailFlightSchoolId: this.schoolId
    };    
  }

}
