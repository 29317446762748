import { Injectable } from "@angular/core";
import { DwAppInitService, DwThemeManagerService } from "@devwareapps/devware-cap";
import { AviatorUser } from "../../shared/models/aviator-user.model";
import { map, mergeMap } from "rxjs/operators";
import { Observable, of } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SchoolBrandingService {

    private currentThemeId: any;
    private changingTheme:boolean = false;

    constructor(private themeManagerService: DwThemeManagerService,
        private appInitService: DwAppInitService) {
            
        }


    previousThemeId: any;
    previousThemeLoaded: boolean = false;

    private loadPreviousTheme() {
        if (this.previousThemeLoaded) {
            return;
        }

        this.previousThemeLoaded = true;

        this.previousThemeId = sessionStorage.getItem('previousThemeId');
    }

    private savePreviousTheme(themeId) {
        sessionStorage.setItem('previousThemeId', themeId);
    }


    setupSchoolBranding(user: AviatorUser): Observable<boolean> {
        this.loadPreviousTheme();

        return this.appInitService.getAppInitResult()
            .pipe(mergeMap(result => {
                let newThemeId = result?.ThemesConfig?.CurrentThemeId;

                if (user.FlightSchoolBrandingThemeId) {
                    newThemeId = user.FlightSchoolBrandingThemeId;
                } else {
                    if (this.previousThemeId) {
                       // console.log(`Using previous theme ${this.previousThemeId} instead of theme ${newThemeId}`);
                        newThemeId = this.previousThemeId;
                    }
                }

                if (!newThemeId || newThemeId == this.currentThemeId) { 

                   // console.log('No theme change required');
                    return of(this.changingTheme);
                }

              //  console.log(`Changing Theme from ${this.currentThemeId} to ${newThemeId}`);

                this.currentThemeId = newThemeId;

                this.changingTheme = true;

                this.savePreviousTheme(this.currentThemeId);

                return this.themeManagerService.applyTheme({
                    ThemeId: newThemeId,
                } as any)
                    .pipe(map(() => {
                        this.changingTheme = false;
                       // console.log(`Chaing Theme COMPLETE from ${this.currentThemeId} to ${newThemeId}`);

                        return this.changingTheme;
                    }));
            }));
    }

}