<div dwRow>
    <div dwCol="3">Payment succeeds: 4242 4242 4242 4242</div>
    <div dwCol="3">Payment requires authentication: 4000 0025 0000 3155</div>
    <div dwCol="3">Payment is declined: 4000 0000 0000 9995</div>
</div><br/>


<!-- <form [formGroup]="formGroup" #addCard="ngForm" (ngSubmit)="onSubmit()" class="create-payment-form"> -->
    <div dwRow>
        <div dwCol="3">
            <button  (click)="syncProducts()">Sync Products</button>
        </div>
        <div dwCol="3">
            <button  (click)="createInvoices()">Create Invoices</button>
        </div>
    </div>
<!-- </form> -->

<div dwRow>
    <div dwCol="1"></div>
    <div dwCol="4">
        <!-- Display a payment form -->
        <div id="checkout">
            <!-- Checkout will insert the payment form here -->
        </div>
    </div>
    <div dwCol="2"></div>
</div>
