import { Component, EventEmitter, Inject, OnInit, Optional } from '@angular/core';
import { DwCardBaseComponent, DwComponent, DwComponentType, DwEventService, DwFormatTypes, DwGridEvents, DwMetaDataFormApi, DwMetaDataFormStateService, DwMetaDataListCardConfigData, DwMetaDataService, DwMetaDataServiceToken } from '@devwareapps/devware-cap';
import { AppMetaData, AppMetaDataItemNames, CourseModuleProgressEntity, LessonTypeAllItems, StudentCourseProgressQueryEntity, StudentLessonProgressQueryEntity, StudentModuleProgressQueryEntity } from '../../../../../meta-data/app-meta-data.service';
import { DwCardBaseConfig } from '../../../../z-devware/cards/models/dw-card-base-config.model';
import { DwCardItemResult } from '../../../../z-devware/cards/models/dw-card-item-result.model';
import { DateTimeUtilService } from '../../../../shared/util/date-time-util.service';
import { CourseProgressVariableContext } from '../../../models/course-progress-variable-context.model';
import { CourseRepositoryService } from '../../../services/course-repository.service';

@DwComponent<DwMetaDataListCardConfigData>({
  name: 'Lesson Card - Student Progress',
  key: 'lessonCard-StudentProgress',
  componentType: DwComponentType.card,
  //configComponent: DwCardDefaultConfigComponent,
  isGlobal: false,
  parentItemName: AppMetaDataItemNames.CourseLessonProgress,
})
@Component({
  selector: 'app-lesson-student-progress-card',
  templateUrl: './lesson-student-progress-card.component.html',
  styleUrls: ['./lesson-student-progress-card.component.scss']
})
export class LessonStudentProgressCardComponent implements DwCardBaseComponent<any, StudentLessonProgressQueryEntity> {
  cardConfig?: any;
  data?: StudentLessonProgressQueryEntity;
  resolvedContext?: any;

  cardDesignerConfig?: DwCardBaseConfig;

  cardItemResult: DwCardItemResult<CourseModuleProgressEntity>;

  progressPercent = 22;

  quizLessonTypeId = LessonTypeAllItems.Quiz;

  queryMetaData: any;
  formApi: DwMetaDataFormApi;
  constructor(
    @Inject(DwMetaDataServiceToken) private dwMetaDataService: DwMetaDataService,
    private dateTimeUtilService: DateTimeUtilService,
    @Optional() private formStateService: DwMetaDataFormStateService,
    private dwEventService: DwEventService,
    private courseRepositoryService: CourseRepositoryService,
  ) {
    this.formApi = this.formStateService?.state?.formApi;
  }

  cardClick = new EventEmitter<any>();

  click() {

    this.cardClick.emit(this.data);
  }

  setCardItemResult(cardItemResult: any): void {
    this.cardItemResult = cardItemResult;
  }

  setupCard() {
    const attrs = AppMetaData.Queries.StudentLessonProgress.Attributes;

    const imageUrls = this.courseRepositoryService.getLessonImageUrl(this.data);

    this.cardDesignerConfig = {
      image: {
        mediaFullUrl: imageUrls.mainImageUrl,
        altImage : {
          mediaFullUrl: imageUrls.backupImageUrl
        },
        // mediaPathField: attrs.LessonImageDwroMediaMediaPath.fieldName,
        // altImage: {
        //   mediaFullUrlField: attrs.VideoThumbnailUrl.fieldName
        // },
        maxWidth: '150px',
        maxHeight: '84px',
      },
      titleField: attrs.CourseLessonLessonName.fieldName,
      descriptionField: '',
      additionalFields: [
        {
          key: 'completed',
          fieldName: attrs.CompletedDateTime.fieldName,
          formatType: DwFormatTypes.date,
          prefix: 'Completed: ',
        },
        {
          key: 'lastActivity',
          fieldName: attrs.LastActivityDateTime.fieldName,
          formatType: DwFormatTypes.date,
          prefix: 'Last Activity: ',
        },
        {
          key: 'percentComplete',
          fieldName: attrs.PercentComplete.fieldName,
          suffix: '%',
        },
        {
          key: 'timeSpent',
          fieldName: attrs.TotalTimeSeconds.fieldName,
          prefix: 'Time: ',
          valueFormatter: (value: any) => {
            return this.dateTimeUtilService.formatDuration(value);
          }
        },
        {
          key: 'quizResult',
          fieldName: attrs.BestQuizResultQuizResultStatusId.fieldName,
          prefix: '',
        },
        {
          key: 'quizScore',
          fieldName: attrs.BestQuizResultPercentageScore.fieldName,
          prefix: ' ',
          suffix: '%',
        },
      ]
    }


    //this.data.BestQuizResultId
    if (!this.queryMetaData) {
      this.dwMetaDataService.getSavedQueryMetaData(AppMetaDataItemNames.Course, null, AppMetaData.Queries.StudentLessonProgress.QueryMeaning, false)
        .subscribe(queryMetaData => {
          this.queryMetaData = queryMetaData;
        });
    }
  }
}
