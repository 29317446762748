import { Inject, Injectable } from "@angular/core";
import { AppMetaData, AppMetaDataItemNames, ProcessorConfigEntity, ProcessorConfigQueryBuilder,
         PaymentMethodEntity } from "../../../meta-data/app-meta-data.service";
import { DevwareMetaData, DwCacheService, DwConfigService, DwConfigServiceToken, DwEventService, DwMetaDataService, DwMetaDataServiceToken, DwOrmData, DwOrmDataService, DwOrmDataServiceToken, DwSecurityTopics, DwSecurityUserService, SuppressErrorMessagesHeader, areEqual, deepCopy } from "@devwareapps/devware-cap";
import { Observable, combineLatest, of } from "rxjs";
import { Router } from "@angular/router";
import { map, mergeMap } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";

@Injectable({ providedIn: 'root' })
export class PaymentsRepositoryService {
   
    constructor(@Inject(DwOrmDataServiceToken) private dwOrmDataService: DwOrmDataService,
        @Inject(DwMetaDataServiceToken) private dwMetaDataService: DwMetaDataService,
        @Inject(DwConfigServiceToken) private configService: DwConfigService,
        private dwSecurityUserService: DwSecurityUserService,
        private dwCacheService: DwCacheService,
        private dwEventService: DwEventService,
        private router: Router,
        private http: HttpClient
    ) {
    }
    getProcessorConfig(flightSchoolId: number): Observable<ProcessorConfigEntity> {
        const cacheInvalidationTopics: string[] = [];
        cacheInvalidationTopics.push(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaDataItemNames.ProcessorConfig));
        const courseKey = `ProcessorConfig_${flightSchoolId}`;
        return this.dwCacheService.get(courseKey, this.loadProcessorConfig(flightSchoolId), null, cacheInvalidationTopics);
    }
    private loadProcessorConfig(flightSchoolId: number): Observable<ProcessorConfigEntity> {
        const query = this.buildLoadProcessorConfigQuery();
        query.addFilterAnd(f => f.Equal(a => a.FlightSchoolId, flightSchoolId));
        return this.dwOrmDataService.executeQuerySingle(query);
    }
    private buildLoadProcessorConfigQuery(): ProcessorConfigQueryBuilder {
        const query = AppMetaData.ProcessorConfig.CreateQueryBuilder();
        query.query.FieldSettings.LoadAllLookupDisplayFields = true;
        const questionLinkQuery = AppMetaData.ProcessorConfig.CreateQueryBuilder();
        return query;
    }

    saveConfig(cfg: ProcessorConfigEntity) : Observable<ProcessorConfigEntity> {
        return this.dwOrmDataService.saveEntity(cfg, false);
    }
    savePayment(cfg: PaymentMethodEntity) : Observable<PaymentMethodEntity> {
        return this.dwOrmDataService.saveEntity(cfg, false);
    }
}