import { Inject, Injectable } from "@angular/core";
import { AppMetaData, AppMetaDataItemNames, ProcessorConfigEntity, ProcessorConfigQueryBuilder,
         PaymentMethodEntity } from "../../../meta-data/app-meta-data.service";
import { DevwareMetaData, DwCacheService, DwConfigService, DwConfigServiceToken, DwEventService, DwMetaDataService, DwMetaDataServiceToken, DwOrmData, DwOrmDataService, DwOrmDataServiceToken, DwSecurityTopics, DwSecurityUserService, SuppressErrorMessagesHeader, areEqual, deepCopy } from "@devwareapps/devware-cap";
import { Observable, combineLatest, of } from "rxjs";
import { Router } from "@angular/router";
import { map, mergeMap } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { PaymentsRepositoryService } from "./payments-repository.service";
import { AviatorUser } from '../../shared/models/aviator-user.model';
import { CreateCustomerResult } from "src/app/features/payments/models/create-customer-result.model";

@Injectable({ providedIn: 'root' })
export class PaymentsIntegrationService {
    paymentsApi: string;
   
    constructor(@Inject(DwOrmDataServiceToken) private dwOrmDataService: DwOrmDataService,
        @Inject(DwMetaDataServiceToken) private dwMetaDataService: DwMetaDataService,
        @Inject(DwConfigServiceToken) private configService: DwConfigService,
        private dwSecurityUserService: DwSecurityUserService,
        private dwCacheService: DwCacheService,
        private dwEventService: DwEventService,
        private paymentsRepoSvc: PaymentsRepositoryService,
        private router: Router,
        private http: HttpClient
    ) {
        this.paymentsApi = `${configService.coreConfig.apiRoot}/aviator-online/Payments`;
    }
    retrieveProcessorConfig() : Observable<ProcessorConfigEntity> {
        const user = this.dwSecurityUserService.securityContext.ApplicationUser;
        const aviatorUser = new AviatorUser(user, null);
        const u = aviatorUser?.user;
        const email = u?.Email;
        const name = `${u?.FirstName} ${u?.LastName}`;
        return this
            .paymentsRepoSvc.getProcessorConfig(aviatorUser.FlightSchoolId)
            .pipe(mergeMap(cfg =>{
                debugger;
                if (cfg != undefined) 
                    return of(cfg);
              
                const url = `${this.paymentsApi}/create-customer`;
                return this.http
                           .post<CreateCustomerResult>(url, { Email: email, Name: name })
                           .pipe(mergeMap(resp => {
                                let newCfg: ProcessorConfigEntity = {
                                    _itemName: AppMetaDataItemNames.ProcessorConfig,
                                    FlightSchoolId: aviatorUser.FlightSchoolId,
                                    CustomerId: resp.Id,
                                };
                                return this.paymentsRepoSvc.saveConfig(newCfg);
                  }));
            }))
    }
}