export const environment = {
    production: false,
    baseTitle: 'Aviator Online',
    environmentMessage: 'Test',
    apiRoot: "https://aviator-online-test-api4.azurewebsites.net/api/v1",
    cdnRoot: "https://aviator-online-test4.azureedge.net/api/v1",
    useCDN: true,
    tenantKey: '16970b66-9788-4e12-aeb3-36bda56bd32f',
  
    allowConfiguration: true,
    enableConsoleLogging: true,
    
    googleAnalyticsTrackingToken: null,
    showContactUs: true,
    contactUsEmail: 'noreply@devwareinc.com',
    disabledUserInactvityTimeout: false
  };
  