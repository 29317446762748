<p>This will remove the student from the current school.  It will remove all instructors and remove from any school groups</p>

<dw-message [(message)]="message"></dw-message>

<ng-container *ngIf="showAdminOptions">
    <div dwRow>
        <div dwCol="12">
            <label class="label">Reassign to Aviator Pro</label>
            <dw-yesno [(ngModel)]="studentChangeSchoolRequest.changeToDefaultSchool"></dw-yesno>
        </div>
    </div>
    <div dwRow [hidden]="studentChangeSchoolRequest.changeToDefaultSchool">
        <div dwCol="12">
            <label class="label">New Flight School</label>
            <dw-select-lookup [lookupId]="fligtSchoolLookupId"
                [(ngModel)]="studentChangeSchoolRequest.newSchoolId" showBlankOption="false"></dw-select-lookup>
        </div>
    </div>
</ng-container>
<div dwRow>
    <div dwCol="6">
        <label class="label">Remove Courses</label>
        <dw-yesno [(ngModel)]="studentChangeSchoolRequest.removeCourses"></dw-yesno>
    </div>
    <div dwCol="6">
        <label class="label">Remove Assignments</label>
        <dw-yesno [(ngModel)]="studentChangeSchoolRequest.removeAssignments"></dw-yesno>
    </div>
</div>