import { Component, EventEmitter, OnInit } from '@angular/core';
import { DwModalHandler, DwModalConfig, DwModalResult, DwButtonConfig, DwButtonCommand, DwMessage, DwSecurityUserService } from '@devwareapps/devware-cap';
import { Observable, of } from 'rxjs';
import { AppMetaDataLookups } from '../../../../meta-data/app-meta-data.service';
import { AdminManagerService } from '../../../admin/services/admin-manager.service';
import { AviatorUser } from '../../../shared/models/aviator-user.model';
import { SchoolRepositoryService } from '../../services/school-repository.service';
import { map } from 'rxjs/operators';
import { AppPermissions } from '../../../../meta-data/app-permissions.enum';
import { StudentChangeSchoolRequest } from '../../models/student-change-school-request.model';

@Component({
  selector: 'app-student-change-school-modal',
  templateUrl: './student-change-school-modal.component.html',
  styleUrls: ['./student-change-school-modal.component.scss']
})
export class StudentChangeSchoolModalComponent implements OnInit, DwModalHandler {
  fligtSchoolLookupId = AppMetaDataLookups.FlightSchoolAllItems;

  constructor(private schoolRepositoryService: SchoolRepositoryService,
    private dwSecurityUserService: DwSecurityUserService,


  ) { }

  modalConfig: DwModalConfig<number>;

  closeModal = new EventEmitter<DwModalResult<any>>();

  message: DwMessage;

  showAdminOptions: boolean = false;
  reassignToAnotherSchool: boolean = false;

  studentChangeSchoolRequest: StudentChangeSchoolRequest;

  ngOnInit(): void {
    this.showAdminOptions = this.dwSecurityUserService.hasPermission(AppPermissions.studentCanManageAll);

    this.studentChangeSchoolRequest = {
      userId: this.modalConfig.data, // Data is the user id
      changeToDefaultSchool: true,
      removeAssignments: false,
      removeCourses: false,
      removeInstructors: true,
      removeSchoolGroups: true,
    }
  }

  buttonClick(buttonConfig: DwButtonConfig): Observable<DwModalResult> {
    let result = new DwModalResult();

    result.cancelled = false;

    switch (buttonConfig.command) {
      case DwButtonCommand.ok:
      case DwButtonCommand.saveAndClose:
      case DwButtonCommand.save:

        return this.executeStudentChangeSchool();

      case DwButtonCommand.cancel:
        result.closeModal = true;
        result.cancelled = true;
    }

    return of(result);
  }

  executeStudentChangeSchool(): Observable<DwModalResult> {

    let result = new DwModalResult();

    result.cancelled = false;

    if (!this.studentChangeSchoolRequest.changeToDefaultSchool && this.showAdminOptions) {
      if (!this.studentChangeSchoolRequest.newSchoolId) {
        this.message = {
          messageBody: 'Please select a new Flight School',
        };

        result.cancelled = true;

        return of(result);
      }
    }

    return this.schoolRepositoryService.studentChangeSchool(this.studentChangeSchoolRequest)
      .pipe(map(studentChangeSchoolResult => {

        if (studentChangeSchoolResult.IsSuccess) {
          result.closeModal = true;

          return result;
        }

        this.message = {
          messageHeading: 'Error changing school',
          messageBody: studentChangeSchoolResult.ErrorMessage
        };

        result.cancelled = true;

        return result;
        
      }));

  }
}
