import { Component, Inject, OnInit } from '@angular/core';
import { DwComponent, DwComponentType, DwEventService, DwExpressionService, DwMessage, DwMessageSeverity, DwMetaDataFormApi, DwMetaDataFormStateService, DwMetaDataService, DwMetaDataServiceToken, DwOrmDataService, DwOrmDataServiceToken, DwSectionBaseComponent, DwUiConfigRegistryService, DwUIMetaDataConfig, DwUIMetaDataConfigToken } from '@devwareapps/devware-cap';
import { SchoolRepositoryService } from 'src/app/features/schools/services/school-repository.service';
import { AppMetaDataItemNames, AssignmentEntity, VideoEntity } from 'src/app/meta-data/app-meta-data.service';
import { VideoEvent } from '../../models/video-event.model';
import { VideoEventType } from '../../models/video-event-type.enum';
import { VideoUploadService } from '../../services/video-upload.service';
import { VideoModalService } from '../../services/video-modal.service';


@DwComponent({
  key: 'videoThumbnailPreview',
  name: 'Video Thumbnail Preview',
  componentType: DwComponentType.formSection,
  isGlobal: true,
  parentItemName: AppMetaDataItemNames.Video,
})
@Component({
  selector: 'app-video-thumbnail',
  template: `
    <img *ngIf="videoEntity" [src]="videoEntity.ThumbnailUrl" style="width:100%;height:auto;max-width:1000px">
  `
})
export class VideoThumbnailComponent extends DwSectionBaseComponent {
  videoEntity: VideoEntity;

  originalAssignment: AssignmentEntity;
  formApi: DwMetaDataFormApi;
  lastTime: number = null;

  constructor(
    dwExpressionService: DwExpressionService,
    protected dwUiConfigRegistryService: DwUiConfigRegistryService,
    @Inject(DwUIMetaDataConfigToken) uiMetaDataConfig: DwUIMetaDataConfig,
    @Inject(DwMetaDataServiceToken) dwMetaDataService: DwMetaDataService,
    @Inject(DwOrmDataServiceToken) private dataService: DwOrmDataService,
    private dwFormStateService: DwMetaDataFormStateService,
    private videoUploadService: VideoUploadService,
    private videoModalService: VideoModalService

  ) {
    super(dwExpressionService, dwUiConfigRegistryService, uiMetaDataConfig, dwMetaDataService);
    this.formApi = this.dwFormStateService.state.formApi;
  }

  ngOnInit(): void {

    this.videoEntity = this.formApi.getFormData<VideoEntity>();

    this.formApi.getFormDataChanges<VideoEntity>()
      .subscribe(videoEntity => {
        this.videoEntity = videoEntity;
      });

  }


  videoEvent(videoEvent: VideoEvent) {

    switch (videoEvent.videoEventType) {
      case VideoEventType.videoStopped:
      case VideoEventType.videoPaused:
        this.lastTime = videoEvent.videoTime;

        break;
    }
  }

  message: DwMessage;

  setThumbnailTime() {
    if (this.lastTime == null) {
      return;
    }

    this.videoUploadService.setThumnbailTime(this.videoEntity.VideoId, this.lastTime)
      .subscribe(result => {
        if (result.IsSuccess) {
          this.message = {
            messageBody: 'Thumbnail set successful.  It might take several seconds for the thumbnail to be updated.  Use the refresh button to see the updated thumbail.',
            severity: DwMessageSeverity.success,
            allowClose: true
          };

          return;
        }

        this.message = {
          messageBody: `Thumbnail set failed. Error : ${result.ErrorMessage}`,
          severity: DwMessageSeverity.error,
          allowClose: true,
        }
      });
  }

  replaceVideoModal() {
    this.videoModalService.showVideoUploadModal('Replace Video', this.videoEntity)
      .subscribe(result => {
        this.formApi.cancel();
      });
  }

  refreshVideo() {
    const videoEntity = this.videoEntity;

    this.videoEntity = null;

    setTimeout(() => {
      this.videoEntity = videoEntity;
    }, 500);
  }

  close() {
    this.formApi.cancel();
  }

}
